import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import home from './home.png'
import b from './b.png'
import left from './left.png'
import right from './right.png'
import c from './c.png'
import d from './d.png'
import e from './e.png'
import f from './f.png'
import g from './g.png'
import h from './h.png'
import i from './i.png'
import one from './one.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import B7 from './B7.png'
import day from './day.png'
import F4 from './F4.png'
import F5 from './F5.png'
import { useNavigate } from 'react-router-dom';

import { MdFacebook } from "react-icons/md";
// #1d3238

const facebook = <div style={{ width: 22, height: 22, backgroundColor: '#1d3238', borderRadius: 11, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={12} /></div>
const facebookb = <div style={{ width: 40, height: 40, backgroundColor: '#1d3238', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={22} /></div>



function Terms() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)



  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [mail, setMail] = useState('')
  const ref = useRef()
  const ref1 = useRef()

  function scroll() {
    ref.current.scrollIntoView()
  }

  useEffect(() => {
    ref1.current.scrollIntoView()
  }, [])

  function ismobile() {
    return width <= 700
  }

  const navigate = useNavigate()

  function openLinked() {
    window.open('https://www.linkedin.com/company/dayswork/', "_blank", "noreferrer");
  }

  function openFace() {
    window.open('https://www.facebook.com/share/9PDgcw7aeC5g5jcp/?mibextid=LQQJ4d', "_blank", "noreferrer");
  }

  function openInstagram() {
    window.open('https://www.instagram.com/daysworkapp?igsh=MXUzMTZsN2EzdnNmMQ%3D%3D&utm_source=qr', "_blank", "noreferrer");
  }

  const term_mobile = <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
    <div ref={ref1} />
    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25' }}>

      <img src={one} style={{ height: 58, alignSelf: 'center' }} />
      <text onClick={() => navigate('/')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 50 }}>
        HOME
      </text>

      <text onClick={() => navigate('/Web2')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        ABOUT US
      </text>

      <text onClick={() => scroll()} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        CONTACT US
      </text>


    </div>

    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img src={left} style={{ width: 0.40 * width, height: 120, alignSelf: 'center' }} />
      <div style={{ width: 194, height: 88, alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
        <text style={{ fontSize: 16, fontWeight: 700, alignSelf: 'center' }}>
          Terms & Conditions
        </text>
      </div>
      <img src={right} style={{ width: 0.40 * width, height: 120, alignSelf: 'center' }} />
    </div>



    <div style={{ display: 'flex', width: 0.8 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -65 }}>



      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        Introduction

      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        Welcome to DaysWork Ltd, an online platform connecting tradesmen with workers through our mobile application ("the App"). By accessing or using the App, you agree to comply with and be bound by these Terms and Conditions (“Terms”).

      </text>

      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        User Agreement

      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        By using our App, you agree to abide by these Terms and any additional terms that apply to specific features or services, which may be presented to you at the time you use such features or services.

      </text>

      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

        Use of the App
      </text>



      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Eligibility:</text> You must be at least 18 years old to use our App. By using the App, you warrant that you are at least 18 years old.
      </text>


      <text style={{ fontSize: 12, fontWeight: 500, marginTop: 10, alignSelf: 'flex-start', fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Registration Information:</text> You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Account Security:</text> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
      </text>












      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

        Services Provided
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        Our App provides a platform for connecting tradesmen with workers for construction-related projects.
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 15, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>No Warranties:</text> We do not provide any warranties or guarantees regarding the quality, reliability, or suitability of the tradesmen or workers connected through our platform.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>User Responsibility:</text> Users are responsible for vetting and verifying the qualifications, credentials, and references of tradesmen or workers before engaging in any work arrangements.

      </text>






      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        User Conduct

      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Lawful Use:</text> You agree not to use the App for any unlawful purpose or in any way that violates these Terms.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Prohibited Activities:</text> You agree not to engage in any activity that could damage, disable, overburden, or impair the functionality of the App.
      </text>

      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins', textAlign: 'left' }}>

        Intellectual Property
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        All content and materials available on the App, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations, and software, are the property of DaysWork Ltd or its licensors and are protected by copyright, trademark,
        and other intellectual property laws.

      </text>


      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        Limitation of Liability

      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        DaysWork Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting
        from (i) your access to or use of or inability to access or use the App.
      </text>
      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        Liability for Job Arrangements

      </text>


      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Independent Contractors:</text> Once a candidate has found a job with a recruiter through the App, both parties acknowledge that DaysWork Ltd is not a party to any agreement between the candidate and the recruiter. All job arrangements are made directly between the candidate and the recruiter.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>No Liability:</text>  DaysWork Ltd shall not be liable for any claims, damages, losses, or disputes arising out of or in connection with the job arrangements between candidates and recruiters. This includes, but is not limited to, issues related to payment, working conditions, job performance, and any form of misconduct.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

        <text style={{ fontSize: 12, fontWeight: 'bold' }}>Risk Acknowledgment:</text> By using the App, you acknowledge and agree that any interactions, communications, or agreements made with other users of the App, including recruiters and candidates, are solely at your own risk.

      </text>









      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

        Indemnification
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        You agree to indemnify and hold harmless DaysWork Ltd and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from
        your use of the App.

      </text>
      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
        Changes to Terms

      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        DaysWork Ltd reserves the right to modify or update these Terms at any time. We will notify users of any material changes to these Terms by posting the updated Terms on the App. Your continued use of the App after any such changes constitutes your acceptance
        of the new Terms.

      </text>
      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

        Governing Law
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

        These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
      </text>



    </div>



    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.98 * width, height: 140, alignItems: 'center', backgroundColor: '#091E25', alignSelf: 'center', justifyContent: 'space-between' }}>

        <div style={{ display: 'flex', flexDirection: 'column', width: 110, height: 110, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start', marginLeft: 4 }} />
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, textAlign: 'left', marginLeft: 4 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            EN9 1EJ
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', width: 80, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 10 }}>

            <div onClick={() => openFace()} style={{ width: 22, height: 22 }}>
              {facebook}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 85, height: 150, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 105, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -16 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 95, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -62 }}>
          <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>   info@dayswork.com</a>

        </div>

      </div>

    </div>

    <div style={{ display: 'flex', width: width, height: 60, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
      <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
        DaysWork Today - A Career Forever
      </text>

    </div>

    <div ref={ref} />

  </div>

  return (ismobile() ? term_mobile :
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div ref={ref1} />

      <div style={{ display: 'flex', backgroundColor: '#091E25', width: width, height: 157, justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: 0.80 * width, height: 156, alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#091E25', alignSelf: 'center' }}>

          <img src={one} style={{ width: 180, height: 125, alignSelf: 'center' }} />

          <div>
            <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', }}>
              HOME
            </text>

            <text onClick={() => navigate('/Web2')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              ABOUT US
            </text>

            <text onClick={() => { scroll() }} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              CONTACT US
            </text>
          </div>

        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={left} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />


        <div style={{ width: 0.10 * width, height: 188, alignItems: 'center', justifyContent: 'center', marginTop: 130, fontFamily: 'Poppins' }}>
          <text style={{ fontSize: 25, fontWeight: 700, alignSelf: 'center' }}>
            Terms & Conditions
          </text>
        </div>

        <img src={right} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />
      </div>




      <div style={{ display: 'flex', width: 0.8 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -420 }}>



        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          Introduction

        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          Welcome to DaysWork Ltd, an online platform connecting tradesmen with workers through our mobile application ("the App"). By accessing or using the App, you agree to comply with and be bound by these Terms and Conditions (“Terms”).

        </text>

        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          User Agreement

        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          By using our App, you agree to abide by these Terms and any additional terms that apply to specific features or services, which may be presented to you at the time you use such features or services.

        </text>

        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

          Use of the App
        </text>



        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Eligibility:</text> You must be at least 18 years old to use our App. By using the App, you warrant that you are at least 18 years old.
        </text>


        <text style={{ fontSize: 20, fontWeight: 500, marginTop: 10, alignSelf: 'flex-start', fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Registration Information:</text> You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Account Security:</text> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
        </text>












        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

          Services Provided
        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          Our App provides a platform for connecting tradesmen with workers for construction-related projects.
        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 15, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>No Warranties:</text> We do not provide any warranties or guarantees regarding the quality, reliability, or suitability of the tradesmen or workers connected through our platform.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>User Responsibility:</text> Users are responsible for vetting and verifying the qualifications, credentials, and references of tradesmen or workers before engaging in any work arrangements.

        </text>






        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          User Conduct

        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Lawful Use:</text> You agree not to use the App for any unlawful purpose or in any way that violates these Terms.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Prohibited Activities:</text> You agree not to engage in any activity that could damage, disable, overburden, or impair the functionality of the App.
        </text>

        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

          Intellectual Property
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          All content and materials available on the App, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations, and software, are the property of DaysWork Ltd or its licensors and are protected by copyright, trademark,
          and other intellectual property laws.

        </text>


        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          Limitation of Liability

        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          DaysWork Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting
          from (i) your access to or use of or inability to access or use the App.
        </text>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          Liability for Job Arrangements

        </text>


        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Independent Contractors:</text> Once a candidate has found a job with a recruiter through the App, both parties acknowledge that DaysWork Ltd is not a party to any agreement between the candidate and the recruiter. All job arrangements are made directly between the candidate and the recruiter.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>No Liability:</text>  DaysWork Ltd shall not be liable for any claims, damages, losses, or disputes arising out of or in connection with the job arrangements between candidates and recruiters. This includes, but is not limited to, issues related to payment, working conditions, job performance, and any form of misconduct.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>

          <text style={{ fontSize: 20, fontWeight: 'bold' }}>Risk Acknowledgment:</text> By using the App, you acknowledge and agree that any interactions, communications, or agreements made with other users of the App, including recruiters and candidates, are solely at your own risk.

        </text>









        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

          Indemnification
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          You agree to indemnify and hold harmless DaysWork Ltd and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from
          your use of the App.

        </text>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>
          Changes to Terms

        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          DaysWork Ltd reserves the right to modify or update these Terms at any time. We will notify users of any material changes to these Terms by posting the updated Terms on the App. Your continued use of the App after any such changes constitutes your acceptance
          of the new Terms.

        </text>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins' }}>

          Governing Law
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>

          These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
        </text>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 397, alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#091E25', marginTop: 40 }}>


        <div style={{ display: 'flex', flexDirection: 'column', width: 305, height: 277, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 187, height: 127, alignSelf: 'flex-start' }} />

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, marginLeft: 6 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 1, marginLeft: 6 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            EN9 1EJ
          </text>
          <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 20 }}>

            <div onClick={() => openFace()} style={{ cursor: 'pointer', width: 40, height: 40 }}>
              {facebookb}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 140, height: 210, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 160, height: 172, alignItems: 'center', justifyContent: 'center', marginTop: -24 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 243, height: 160, alignItems: 'center', justifyContent: 'center', marginTop: -105 }}>
          <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: 233, alignItems: 'center', justifyContent: 'flex-start', marginTop: 5 }}>
            <img src={F4} style={{ width: 18, height: 18, alignSelf: 'flex-start' }} />
            <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 15, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 5 }}>
              info@dayswork.com
            </a>
          </div>

        </div>

      </div>

      <div style={{ display: 'flex', width: width, height: 93, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
        <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'center', color: '#091E25' }}>
          DaysWork Today - A Career Forever
        </text>

      </div>

      <div ref={ref} />

    </div>


  );
}

export default Terms;