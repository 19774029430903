import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import A1 from './A1.png'
import A2 from './A3.png'
import A from './A.png'
import A3 from './A3.png'
import A4 from './A4.png'
import A5 from './A5.png'
import A6 from './A6.png'
import A7 from './A7.png'
import A8 from './A8.png'
import A9 from './A9.png'
import A10 from './A10.png'
import j from './j.png'
import B1 from './B1.png'
import B2 from './B2.png'
import B3 from './B3.png'
import B4 from './B4.png'
import B5 from './B5.png'
import B6 from './B6.png'
import cc from './cc.png'
import B8 from './B8.png'
import B9 from './B9.png'


import B7 from './B7.png'
import gc from './gc.png'
import gd from './gd.png'
import one from './one.png'
import y from './y.png'
import google from './google.png'
import apple from './apple.png'
import n from './n.png'
import nn from './nn.png'

import B10 from './B10.png'
import C1 from './C1.png'
import C2 from './C2.png'
import C7 from './C7.png'
import C8 from './C8.png'
import C9 from './C9.png'
import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import D9 from './D9.png'
import E1 from './E1.png'
import E2 from './E2.png'
import E3 from './E3.png'
import E4 from './E4.png'
import E5 from './E5.png'
import E6 from './E6.png'
import E7 from './E7.png'
import E8 from './E8.png'
import E9 from './E9.png'
import F1 from './F1.png'
import F2 from './F2.png'
import map from './map.png'
import map2 from './map2.png'
import F3 from './F3.png'
import F4 from './F4.png'
import F5 from './F5.png'
import G2 from './G2.png'
import { toast } from 'wc-toast';
import jsonp from 'jsonp';
import { Link, useNavigate } from 'react-router-dom';
import { MdFacebook } from "react-icons/md";
// #1d3238

const facebook = <div style={{ width: 22, height: 22, backgroundColor: '#1d3238', borderRadius: 11, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={12} /></div>
const facebookb = <div style={{ width: 40, height: 40, backgroundColor: '#1d3238', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={22} /></div>



function Web1() {

     function openLinked() {
          window.open('https://www.linkedin.com/company/dayswork/', "_blank", "noreferrer");
     }

     function openFace() {
          window.open('https://www.facebook.com/share/9PDgcw7aeC5g5jcp/?mibextid=LQQJ4d', "_blank", "noreferrer");
     }

     function openInstagram() {
          window.open('https://www.instagram.com/daysworkapp?igsh=MXUzMTZsN2EzdnNmMQ%3D%3D&utm_source=qr', "_blank", "noreferrer");
     }

     const [width, setWidth] = useState(window.innerWidth)
     const [height, setHeight] = useState(window.innerHeight)

     const navigate = useNavigate()

     function handleWindowSizeChange() {
          setWidth(window.innerWidth);
          setHeight(window.innerHeight);
     }

     useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
          return () => {
               window.removeEventListener('resize', handleWindowSizeChange);
          }
     }, []);

     const [mail, setMail] = useState('')
     const ref1 = useRef()
     const ref = useRef()
     const sectionRefs = useRef({});

     const scrollToSection = (sectionId) => {
          if (sectionRefs.current[sectionId]) {
               sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
     };


     const addToRefs = (el, id) => {
          if (el && !sectionRefs.current[id]) {
               sectionRefs.current[id] = el;
          }
     };

     function scroll() {
          ref.current.scrollIntoView()
     }

     useEffect(() => {
          ref1.current.scrollIntoView()
     }, [])

     function submit() {
          // https://dayswork.us22.list-manage.com/subscribe/post-json?u=2cacf7115c5b4f691e362274a&amp;id=5524d3f8f4&amp;f_id=00cfc8e1f0
          const url = 'https://dayswork.us22.list-manage.com/subscribe/post-json?u=2cacf7115c5b4f691e362274a&amp;id=5524d3f8f4&amp;f_id=00cfc8e1f0';
          jsonp(`${url}&EMAIL=${mail}`, { param: 'c' }, (_, data) => {
               const { msg, result } = data
               console.log('Msg ', msg, result)
               if (result == 'success') {
                    toast('Thank you for subscribing!')
               }
               else toast(msg)
          });
     }

     function ismobile() {
          return width <= 700
     }


     const mobile_1 = <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: width }}>
          <div ref={ref1} />
          <img src={j} style={{ width: width, height: 290, alignSelf: 'center' }} />

          <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', marginTop: -290 }}>

               <Link to='/'>     <img src={one} style={{ height: 58, alignSelf: 'center' }} /></Link>
               <text style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 50 }}>
                    HOME
               </text>

               <text onClick={() => navigate('/Web2')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
                    ABOUT US
               </text>

               <text onClick={() => scroll()} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
                    CONTACT US
               </text>


          </div>


          <div style={{ width: 280, height: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: 0, marginTop: -7 }}>
               <img src={y} style={{ width: 150, height: 50, alignSelf: 'center' }} />



               <div style={{ width: 130, height: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignSelf: 'center', alignItems: 'center', marginLeft: 10, marginTop: 0 }}>
                    <img src={google} style={{ width: 65, height: 23, alignSelf: 'center', marginRight: 3 }} />
                    <img src={apple} style={{ width: 65, height: 23, alignSelf: 'center', marginLeft: 3 }} />
               </div>

          </div>


          <div style={{ width: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'flex-start', marginTop: 12 }}>

               <div style={{ width: 280, height: 5, display: 'flex', justifyContent: 'left', alignSelf: 'center', marginTop: 0, }}>
                    <text style={{ fontSize: 11, fontWeight: 600, alignSelf: 'center', color: '#FFFFFF' }}>
                         Signup to our mailing list for when we go LIVE!
                    </text>
               </div>

               <div style={{ display: 'flex', width: 280, height: 32, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginTop: 12 }}>
                    {/* <div style={{ display: 'flex', width: 200, height: 37, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}> */}
                    <input placeholder='Email address' value={mail} onChange={(e) => setMail(e.currentTarget.value)} style={{ borderRadius: 8, color: '#B2BEB5', outline: 'none', display: 'flex', width: 168, paddingLeft: 16, paddingRight: 16, height: 35, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none', fontWeight: '600', fontSize: 10 }} />
                    {/* </div> */}
                    <div onClick={() => submit()} style={{ display: 'flex', width: 55, height: 34, borderRadius: 8, backgroundColor: '#8DC63F', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                         <text style={{ fontSize: 10, fontWeight: 400, alignSelf: 'center', color: 'white' }}>
                              Sign Up
                         </text>
                    </div>
               </div>
          </div>

          <wc-toast></wc-toast>


          <img src={gc} style={{ width: 295, height: 300, alignSelf: 'end', marginTop: 0 }} />

          <div ref={(el) => addToRefs(el, 'aboutSection')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>

               <img src={n} style={{ width: 350, height: 400, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                    <text style={{ fontSize: 20, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                         About the App
                    </text>
                    <text style={{ fontSize: 40, fontWeight: 700, alignSelf: 'flex-start', fontFamily: 'Poppins', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         What is Dayswork App?
                    </text>
                    <text style={{ width: 0.8 * width, fontSize: 18, fontWeight: 400, display: 'flex', fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         DaysWork is a platform designed to connect job seekers with both small and large businesses, facilitating an easy way for individuals to find work opportunities and for businesses to find suitable workers. Users can browse job listings, create profiles, and connect with potential employers or employees based on their needs and qualifications. The platform aims to streamline the job search process and foster efficient matches between job seekers and businesses.
                    </text>


                    {/* <div style={{ width: 220, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>
          </div>


          <div ref={(el) => addToRefs(el, 'availability')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={A1} style={{ width: 350, height: 400, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                         Availability
                    </text>
                    <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         Availability feature?
                    </text>

                    <text style={{ fontSize: 18, display: 'flex', width: 0.8 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         Candidates on DaysWork can easily set their availability in real time for recruiters to view by accessing their profile settings. Within the app, candidates can input their preferred days of the week they are available. This information is then visible to recruiters, allowing them to match candidates with suitable job opportunities that align with their availability. By enabling candidates to customise their availability, DaysWork ensures a more efficient and tailored job matching process for both candidates and recruiters.
                    </text>

                    {/* <div style={{ width: 230, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'center', color: '#8DC63F' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>

          </div>


          <img src={map} style={{ width: '100%', alignSelf: 'center' }} />
          <div style={{ display: 'flex', width: width, height: 260, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', marginTop: -260 }}>
               <img src={map2} style={{ width: (width) / 2, height: 260, alignSelf: 'center' }} />
          </div>
          <div ref={(el) => addToRefs(el, 'location')} style={{ display: 'flex', fontFamily: 'Poppins', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', }}>

               <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: 'black', marginTop: 30 }}>
                    Location
               </text>
               <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                    Location feature?
               </text>

               <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                    In the DaysWork app, users can easily set their location and adjust the distance radius to find work opportunities or workers within a specific geographic area. By setting their location, users can ensure that they are searching for jobs or workers in the desired area. The adjustable distance radius feature allows users to specify how far they are willing to travel for work or how close they prefer the job location to be. This functionality enables users to personalise their job search experience and focus on opportunities that are conveniently located within their preferred distance range.
               </text>


               {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                    <text style={{ fontSize: 16, fontFamily: 'Poppins', fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: 'black' }}>
                         Learn More
                    </text>
                    <img src={F3} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
               </div> */}

          </div>



          <div ref={(el) => addToRefs(el, 'review')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', marginTop: 20 }}>
               <img src={A4} style={{ width: 350, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                         Review
                    </text>
                    <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         Reviewing profiles before hiring?
                    </text>

                    <text style={{ fontSize: 18, display: 'flex', width: 0.8 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         Recruiters on the DaysWork app have the ability to preview candidates' reviews and job profiles before making hiring decisions. By accessing the candidate's profile, recruiters can view reviews and ratings provided by previous employers or clients, giving them insight into the candidate's work ethic, skills, and professionalism. Additionally, recruiters can review the candidate's job profile, which includes details such as work experience, skills, education, and any additional information the candidate has chosen to share. This feature allows recruiters to make informed decisions based on the candidate's background and feedback from previous engagements, enhancing the hiring process and ensuring the selection of the most suitable candidates for the job.
                    </text>

                    {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>

          </div>



          <div ref={(el) => addToRefs(el, 'postjob')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={A5} style={{ width: 350, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.8 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                         Jobs
                    </text>
                    <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         How easy it is to post a job, and how many jobs?
                    </text>

                    <text style={{ fontSize: 18, display: 'flex', width: 0.8 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         On the DaysWork app, recruiters can easily post a job by following a few simple steps. They can create a job listing by providing details such as job title, description, requirements, and location. Recruiters can then select a specific subscription based on how many candidates they wish to hire. The app offers various subscription plans that allow recruiters to access a certain number of candidate profiles based on their hiring needs. Recruiters can choose a subscription that aligns with the number of candidates they want to hire, making it a flexible and convenient option for businesses of all sizes. This streamlined process enables recruiters to efficiently post job listings and select the subscription plan that best fits their hiring requirements.

                    </text>

                    {/* <div style={{ width: 220, height: 32, fontFamily: 'Poppins', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                         <text style={{ fontSize: 16, fontWeight: 600, alignSelf: 'center', color: '#8DC63F', textAlign: 'left' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>
          </div>



          <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={A7} style={{ width: 350, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', textAlign: 'left' }}>
                         Target Market
                    </text>
                    <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         Focusing on construction and industrial for now, but future industries on their way?
                    </text>

                    <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         Initially focused on construction and industrial sectors, the DaysWork app aims to streamline job matching specifically for these industries. In the future, the platform plans to expand its reach by introducing additional industry categories to cater to a broader range of job seekers and businesses. This expansion will provide opportunities for individuals across various sectors to find work easily and for businesses to connect with skilled workers in diverse fields, enhancing the app's versatility and utility for a wider user base.
                    </text>

                    {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>

          </div>




          <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', marginTop: 60 }}>
               <img src={A9} style={{ width: 350, height: 490, alignSelf: 'center' }} />

               <div style={{ display: 'flex', flexDirection: 'column', width: 0.8 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         How will the app help?
                    </text>
                    <text style={{ fontSize: 35, fontWeight: 700, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                         How the app will help people looking for work, and small/large businesses in the UK looking for workers; ultimately the mission being boosting the economy

                    </text>

                    <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         The DaysWork app is intricately crafted to support individuals and businesses in the UK, with the ultimate goal of enhancing the economy. By facilitating seamless connections between job seekers and businesses, the app helps create employment opportunities, reduce unemployment rates, and boost consumer spending. For businesses, DaysWork streamlines the recruitment process, enabling them to find skilled workers efficiently, enhance productivity, and compete more effectively in the market. By fostering skill development, supporting small businesses, and promoting economic growth at the local level, the app plays a pivotal role in driving economic prosperity and sustainability across various sectors in the UK.
                    </text>
                    {/* 
                    <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn more
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>

          </div>

          <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={B2} style={{ width: 350, height: 337, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>



                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         How we work
                    </text>
                    <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0 }}>
                         Modern Slavery Act?
                    </text>

                    <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         DaysWork will ensure that businesses and individuals on the platform comply with the Modern Slavery Act by verifying their adherence to ethical standards, raising awareness about the Act's requirements, monitoring activities for compliance, and establishing partnerships with relevant organisations to prevent modern slavery and human trafficking.
                    </text>



                    {/* <div style={{ width: 195, fontFamily: 'Poppins', height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20, textAlign: 'left' }}>
                         <text style={{ fontSize: 16, fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}

               </div>

          </div>


          <div ref={(el) => addToRefs(el, 'mission')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={B3} style={{ width: 350, height: 313, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginTop: 10 }}>
                         Our Mission
                    </text>
                    <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0 }}>
                         Mission
                    </text>

                    <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         Our mission is to empower individuals by providing access to daily work opportunities and assisting them in transitioning back into sustainable employment. Through our platform, we connect motivated workers with employers seeking immediate assistance, fostering economic mobility and fostering a vibrant workforce.
                    </text>

                    {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn more
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}

               </div>

          </div>


          <div ref={(el) => addToRefs(el, 'stats')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center' }}>
               <img src={B6} style={{ width: 350, height: 396, alignSelf: 'center' }} />
               <div style={{ display: 'flex', flexDirection: 'column', width: 0.80 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Stats
                    </text>
                    <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0 }}>
                         Statistics
                    </text>


                    <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                         <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                         <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.8 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                              According to a recent industry report, there is a projected need for 937,000 new workers in the construction and trade industries by 2032 to address the increasing demand for skilled labor in these sectors.
                         </text>
                    </div>

                    <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                         <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                         <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.8 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                              The construction and building trades have witnessed a significant decline in the number of workers, with a notable decrease of 184,000 individuals since 2006.
                         </text>
                    </div>

                    <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                         <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                         <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.8 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                              In late 2023 to early 2024, over 350,000 18 to 24-year-olds were unemployed, with 1.9 million economically inactive and 3.3 million employed. The unemployment rate for this age group rose to 10.3% from 9.7% in the previous year.
                         </text>
                    </div>




                    {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}
               </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>

               <div style={{ display: 'flex', flexDirection: 'row', width: 0.98 * width, height: 140, alignItems: 'center', backgroundColor: '#091E25', alignSelf: 'center', justifyContent: 'space-between' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 110, height: 110, alignItems: 'center', justifyContent: 'center' }}>
                         <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start', marginLeft: 4 }} />
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, textAlign: 'left', marginLeft: 4 }}>
                              Knight Wheeler & Co
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
                              54 Sun Street
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
                              Waltham Abbey
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
                              EN9 1EJ
                         </text>

                         <div style={{ display: 'flex', flexDirection: 'row', width: 80, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 10 }}>

                              <div onClick={() => openFace()} style={{ width: 22, height: 22 }}>
                                   {facebook}
                              </div>
                              <img onClick={() => openInstagram()} src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
                              <img onClick={() => openLinked()} src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
                         </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 85, height: 150, alignItems: 'center', justifyContent: 'center' }}>
                         <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Home
                         </text>

                         <text onClick={() => scrollToSection('aboutSection')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              About the App
                         </text>

                         <text onClick={() => scrollToSection('availability')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Availability
                         </text>

                         <text onClick={() => scrollToSection('location')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Location Feature
                         </text>

                         <text onClick={() => scrollToSection('mission')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Mission
                         </text>

                         <text onClick={() => scrollToSection('jobpost')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Posting a Job
                         </text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 105, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -16 }}>
                         <text onClick={() => scrollToSection('review')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Review Profiles
                         </text>

                         <text onClick={() => navigate('/Abstract')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Slavery Act
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Statistics
                         </text>

                         <text onClick={() => navigate('/Privacy')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Privacy Policy
                         </text>

                         <text onClick={() => navigate('/Terms')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Terms & Conditions
                         </text>


                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 95, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -62 }}>
                         <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Contact Us
                         </text>

                         <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>   info@dayswork.com</a>


                    </div>

               </div>

          </div>

          <div style={{ display: 'flex', width: width, height: 60, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
               <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
                    DaysWork Today - A Career Forever
               </text>

          </div>

          <div ref={ref} />

     </div>

     return (ismobile() ? mobile_1 :
          <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
               <div ref={ref1} />
               <wc-toast></wc-toast>
               <img src={j} style={{ width: width, height: 837, alignSelf: 'center' }} />

               <div style={{ display: 'flex', flexDirection: 'row', width: 0.80 * width, height: 157, alignItems: 'center', justifyContent: 'space-between', marginTop: -837, alignSelf: 'center' }}>

                    <Link to='/' >  <img src={one} style={{ width: 180, height: 125, alignSelf: 'center' }} /> </Link>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                         <text style={{ fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 0, marginTop: 0 }}>
                              HOME
                         </text>

                         <text onClick={() => navigate('/Web2')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40, marginTop: 0 }}>
                              ABOUT US
                         </text>

                         <text onClick={() => { scroll() }} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40, marginTop: 0 }}>
                              CONTACT US
                         </text>

                    </div>

               </div>
               <div style={{ width: width, height: 646, display: 'flex', flexDirection: 'row', marginTop: 55, justifyContent: 'center', alignSelf: 'center', alignItems: 'center' }}>

                    <div style={{ width: 0.80 * width, height: 379, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', marginBottom: 160 }}>
                         <img src={y} style={{ width: 480, height: 176, alignSelf: 'flex-start', marginLeft: 30 }} />


                         <div style={{ width: 480, height: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 43, marginTop: 0, }}>
                              <text style={{ fontSize: 18, fontWeight: 600, textAlign: 'center', color: '#FFFFFF' }}>
                                   Looking for a DaysWork? We can help you get that job!
                              </text>

                         </div>

                         <div style={{ marginLeft: 57, width: 460, height: 56, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'flex-start', alignItems: 'flex-start', marginTop: 15, }}>
                              <img src={google} style={{ width: 190, height: 56, alignSelf: 'flex-start', marginRight: 20 }} />
                              <img src={apple} style={{ width: 190, height: 56, alignSelf: 'flex-start', marginLeft: 0 }} />
                         </div>



                         <div style={{ width: 570, height: 67, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'center', marginLeft: 90, marginTop: 140, }}>

                              <div style={{ width: 515, height: 10, display: 'flex', justifyContent: 'center', alignSelf: 'center', marginLeft: 0, marginTop: 0, }}>
                                   <text style={{ fontSize: 22, fontWeight: 600, alignSelf: 'center', color: '#FFFFFF' }}>
                                        Signup to our mailing list for when we go LIVE!
                                   </text>
                              </div>

                              <div style={{ display: 'flex', width: 470, height: 57, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginTop: 15, marginLeft: 0 }}>
                                   <div style={{ display: 'flex', width: 350, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>

                                        <input placeholder='Email address' value={mail} onChange={(e) => setMail(e.currentTarget.value)} style={{ color: '#B2BEB5', marginLeft: 10, outline: 'none', display: 'flex', width: 265, height: 50, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none', fontWeight: '600', fontSize: 18 }} />

                                   </div>
                                   <div onClick={() => submit()} style={{ cursor: 'pointer', display: 'flex', width: 93, height: 50, borderRadius: 8, backgroundColor: '#8DC63F', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                                        <text style={{ fontSize: 18, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
                                             Sign Up
                                        </text>
                                   </div>
                              </div>
                         </div>

                    </div>
                    <img src={gc} style={{ width: 540, height: 556, alignSelf: 'end', marginBottom: 60, marginLeft: 50 }} />
               </div>


               <div ref={(el) => addToRefs(el, 'aboutSection')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>

                    <img src={n} style={{ width: 482, height: 482, alignSelf: 'center' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                         <text style={{ fontSize: 18, fontWeight: '600', fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                              About the App
                         </text>
                         <text style={{ fontSize: 40, fontWeight: '700', alignSelf: 'flex-start', fontFamily: 'Poppins', color: '#000000', marginRight: 0 }}>
                              What is Dayswork App?
                         </text>
                         <text style={{ width: 0.447 * width, fontSize: 18, fontWeight: '400', display: 'flex', fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              DaysWork is a platform designed to connect job seekers with both small and large businesses, facilitating an easy way for individuals to find work opportunities and for businesses to find suitable workers. Users can browse job listings, create profiles, and connect with potential employers or employees based on their needs and qualifications. The platform aims to streamline the job search process and foster efficient matches between job seekers and businesses.
                         </text>


                         {/* <div style={{ width: 220, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: '600', alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn about Dayswork
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>
               </div>


               <div ref={(el) => addToRefs(el, 'availability')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                              Availability
                         </text>
                         <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0 }}>
                              Availability feature?
                         </text>

                         <text style={{ fontSize: 18, display: 'flex', width: 0.447 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              Candidates on DaysWork can easily set their availability in real time for recruiters to view by accessing their profile settings. Within the app, candidates can input their preferred days of the week they are available. This information is then visible to recruiters, allowing them to match candidates with suitable job opportunities that align with their availability. By enabling candidates to customise their availability, DaysWork ensures a more efficient and tailored job matching process for both candidates and recruiters.
                         </text>

                         {/* <div style={{ width: 230, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn about Dayswork
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>
                    <img src={A1} style={{ width: 525, height: 595, alignSelf: 'center' }} />
               </div>

               <img src={map} style={{ width: width, height: 667, alignSelf: 'center' }} />
               <div style={{ display: 'flex', width: width, height: 667, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-end', marginTop: -667 }}>
                    <img src={map2} style={{ width: 667, height: 667, alignSelf: 'center' }} />
               </div>
               <div ref={(el) => addToRefs(el, 'location')} style={{ display: 'flex', fontFamily: 'Poppins', flexDirection: 'column', width: 0.45 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: -520, marginLeft: 0.45 * width }}>

                    <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: 'black', marginRight: 0 }}>
                         Location
                    </text>
                    <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0 }}>
                         Location feature?
                    </text>

                    <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.4 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                         In the DaysWork app, users can easily set their location and adjust the distance radius to find work opportunities or workers within a specific geographic area. By setting their location, users can ensure that they are searching for jobs or workers in the desired area. The adjustable distance radius feature allows users to specify how far they are willing to travel for work or how close they prefer the job location to be. This functionality enables users to personalise their job search experience and focus on opportunities that are conveniently located within their preferred distance range.
                    </text>


                    {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                         <text style={{ fontSize: 16, fontFamily: 'Poppins', fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: 'black' }}>
                              Learn More
                         </text>
                         <img src={F3} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                    </div> */}

               </div>


               <div ref={(el) => addToRefs(el, 'review')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center', marginTop: 198 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                              Review
                         </text>
                         <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              Reviewing profiles before hiring?
                         </text>

                         <text style={{ fontSize: 18, display: 'flex', width: 0.447 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              Recruiters on the DaysWork app have the ability to preview candidates' reviews and job profiles before making hiring decisions. By accessing the candidate's profile, recruiters can view reviews and ratings provided by previous employers or clients, giving them insight into the candidate's work ethic, skills, and professionalism. Additionally, recruiters can review the candidate's job profile, which includes details such as work experience, skills, education, and any additional information the candidate has chosen to share. This feature allows recruiters to make informed decisions based on the candidate's background and feedback from previous engagements, enhancing the hiring process and ensuring the selection of the most suitable candidates for the job.
                         </text>

                         {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn More
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>
                    <img src={A4} style={{ width: 498, height: 664, alignSelf: 'center', marginLeft: 20 }} />
               </div>


               <div ref={(el) => addToRefs(el, 'postjob')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>
                    <img src={A5} style={{ width: 520, height: 504, alignSelf: 'center' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.45 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              Jobs
                         </text>
                         <text style={{ fontSize: 40, fontWeight: 700, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              How easy it is to post a job, and how many jobs?
                         </text>

                         <text style={{ fontSize: 18, display: 'flex', width: 0.447 * width, fontWeight: 400, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              On the DaysWork app, recruiters can easily post a job by following a few simple steps. They can create a job listing by providing details such as job title, description, requirements, and location. Recruiters can then select a specific subscription based on how many candidates they wish to hire. The app offers various subscription plans that allow recruiters to access a certain number of candidate profiles based on their hiring needs. Recruiters can choose a subscription that aligns with the number of candidates they want to hire, making it a flexible and convenient option for businesses of all sizes. This streamlined process enables recruiters to efficiently post job listings and select the subscription plan that best fits their hiring requirements.

                         </text>

                         {/* <div style={{ width: 220, height: 32, fontFamily: 'Poppins', display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn about Dayswork
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>


               </div>


               <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              Target Market
                         </text>
                         <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              Focusing on construction and industrial for now, but future industries on their way?
                         </text>

                         <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              Initially focused on construction and industrial sectors, the DaysWork app aims to streamline job matching specifically for these industries. In the future, the platform plans to expand its reach by introducing additional industry categories to cater to a broader range of job seekers and businesses. This expansion will provide opportunities for individuals across various sectors to find work easily and for businesses to connect with skilled workers in diverse fields, enhancing the app's versatility and utility for a wider user base.
                         </text>

                         {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn More
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>
                    <img src={A7} style={{ width: 365, height: 504, alignSelf: 'center', marginLeft: 50 }} />
               </div>


               <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center', marginTop: 60 }}>
                    <img src={A9} style={{ width: 302, height: 504, alignSelf: 'center', marginRight: 110 }} />

                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontWeight: 600, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              How will the app help?
                         </text>
                         <text style={{ fontSize: 35, fontWeight: 700, display: 'flex', width: 0.43 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              How the app will help people looking for work, and small/large businesses in the UK looking for workers; ultimately the mission being boosting the economy

                         </text>

                         <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              The DaysWork app is intricately crafted to support individuals and businesses in the UK, with the ultimate goal of enhancing the economy. By facilitating seamless connections between job seekers and businesses, the app helps create employment opportunities, reduce unemployment rates, and boost consumer spending. For businesses, DaysWork streamlines the recruitment process, enabling them to find skilled workers efficiently, enhance productivity, and compete more effectively in the market. By fostering skill development, supporting small businesses, and promoting economic growth at the local level, the app plays a pivotal role in driving economic prosperity and sustainability across various sectors in the UK.
                         </text>

                         {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn more
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>

               </div>



               <div style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              How we work
                         </text>
                         <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              Modern Slavery Act?
                         </text>

                         <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              DaysWork will ensure that businesses and individuals on the platform comply with the Modern Slavery Act by verifying their adherence to ethical standards, raising awareness about the Act's requirements, monitoring activities for compliance, and establishing partnerships with relevant organisations to prevent modern slavery and human trafficking.
                         </text>


                         {/* <div style={{ width: 195, fontFamily: 'Poppins', height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn More
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}

                    </div>
                    <img src={B2} style={{ width: 505, height: 337, alignSelf: 'center' }} />
               </div>

               <div ref={(el) => addToRefs(el, 'mission')} style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>
                    <img src={B3} style={{ width: 469, height: 313, alignSelf: 'center', marginRight: 60 }} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              Our Mission
                         </text>
                         <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              Mission
                         </text>

                         <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
                              Our mission is to empower individuals by providing access to daily work opportunities and assisting them in transitioning back into sustainable employment. Through our platform, we connect motivated workers with employers seeking immediate assistance, fostering economic mobility and fostering a vibrant workforce.
                         </text>

                         {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn more
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}

                    </div>

               </div>

               <div ref={(el) => addToRefs(el, 'stats')} id='stats' style={{ width: 0.85 * width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

                         <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
                              Stats
                         </text>
                         <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
                              Statistics
                         </text>


                         <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                              <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.447 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                                   According to a recent industry report, there is a projected need for 937,000 new workers in the construction and trade industries by 2032 to address the increasing demand for skilled labor in these sectors.
                              </text>
                         </div>

                         <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                              <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.447 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                                   The construction and building trades have witnessed a significant decline in the number of workers, with a notable decrease of 184,000 individuals since 2006.
                              </text>
                         </div>

                         <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                              <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
                              <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.447 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
                                   In late 2023 to early 2024, over 350,000 18 to 24-year-olds were unemployed, with 1.9 million economically inactive and 3.3 million employed. The unemployment rate for this age group rose to 10.3% from 9.7% in the previous year.
                              </text>
                         </div>




                         {/* <div style={{ width: 195, height: 32, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 20 }}>
                              <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F' }}>
                                   Learn More
                              </text>
                              <img src={F2} style={{ width: 12, height: 10, alignSelf: 'center', marginLeft: 10, marginTop: 4 }} />
                         </div> */}
                    </div>
                    <img src={B6} style={{ width: 396, height: 396, alignSelf: 'center' }} />
               </div>



               <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 397, alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#091E25', marginTop: 40 }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 305, height: 277, alignItems: 'center', justifyContent: 'center' }}>
                         <img src={B7} style={{ width: 187, height: 127, alignSelf: 'flex-start' }} />

                         <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, marginLeft: 6 }}>
                              Knight Wheeler & Co
                         </text>

                         <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
                              54 Sun Street
                         </text>

                         <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 1, marginLeft: 6 }}>
                              Waltham Abbey
                         </text>

                         <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
                              EN9 1EJ
                         </text>
                         <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 20 }}>

                              <div onClick={() => openFace()} style={{ cursor: 'pointer', width: 40, height: 40 }}>
                                   {facebookb}
                              </div>
                              <img onClick={() => openInstagram()} src={B10} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
                              <img onClick={() => openLinked()} src={C1} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
                         </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 140, height: 210, alignItems: 'center', justifyContent: 'center' }}>
                         <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Home
                         </text>

                         <text onClick={() => scrollToSection('aboutSection')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              About the App
                         </text>

                         <text onClick={() => scrollToSection('availability')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Availability
                         </text>

                         <text onClick={() => scrollToSection('location')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Location Feature
                         </text>

                         <text onClick={() => scrollToSection('mission')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Mission
                         </text>

                         <text onClick={() => scrollToSection('postjob')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Posting a Job
                         </text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 160, height: 172, alignItems: 'center', justifyContent: 'center', marginTop: -24 }}>
                         <text onClick={() => scrollToSection('review')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Review Profiles
                         </text>

                         <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Slavery Act
                         </text>

                         <text onClick={() => scrollToSection('stats')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Statistics
                         </text>

                         <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Privacy Policy
                         </text>

                         <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Terms & Conditions
                         </text>


                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 243, height: 160, alignItems: 'center', justifyContent: 'center', marginTop: -105 }}>
                         <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Contact Us
                         </text>

                         <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: 233, alignItems: 'center', justifyContent: 'flex-start', marginTop: 5 }}>
                              <img src={F4} style={{ width: 18, height: 18, alignSelf: 'flex-start' }} />
                              <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 15, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 5 }}>
                                   info@dayswork.com
                              </a>
                         </div>

                    </div>

               </div>

               <div style={{ display: 'flex', width: width, height: 93, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'center', color: '#091E25' }}>
                         DaysWork Today - A Career Forever
                    </text>

               </div>

               <div ref={ref} />


          </div>
     );
}

export default Web1;
