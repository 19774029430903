import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import home from './home.png'
import b from './b.png'
import left from './left.png'
import right from './right.png'
import c from './c.png'
import d from './d.png'
import e from './e.png'
import f from './f.png'
import g from './g.png'
import h from './h.png'
import i from './i.png'
import one from './one.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import B7 from './B7.png'
import day from './day.png'
import F4 from './F4.png'
import F5 from './F5.png'
import { useNavigate } from 'react-router-dom';
import { MdFacebook } from "react-icons/md";
// #1d3238

const facebook = <div style={{ width: 22, height: 22, backgroundColor: '#1d3238', borderRadius: 11, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={12} /></div>
const facebookb = <div style={{ width: 40, height: 40, backgroundColor: '#1d3238', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={22} /></div>


function Privacy() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const navigate = useNavigate()

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [mail, setMail] = useState('')
  const ref1 = useRef()
  const ref = useRef()

  function scroll() {
    ref.current.scrollIntoView()
  }

  useEffect(() => {
    ref1.current.scrollIntoView()
  }, [])

  function ismobile() {
    return width <= 700
  }

  function sendMail() {
    window.open('mailto:info@dayswork.com?subject=subject&body=body')
  }

  function openLinked() {
    window.open('https://www.linkedin.com/company/dayswork/', "_blank", "noreferrer");
  }

  function openFace() {
    window.open('https://www.facebook.com/share/9PDgcw7aeC5g5jcp/?mibextid=LQQJ4d', "_blank", "noreferrer");
  }

  function openInstagram() {
    window.open('https://www.instagram.com/daysworkapp?igsh=MXUzMTZsN2EzdnNmMQ%3D%3D&utm_source=qr', "_blank", "noreferrer");
  }

  const mobile_privacy = <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
    <div ref={ref1} />
    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25' }}>

      <img src={one} style={{ height: 58, alignSelf: 'center' }} />
      <text onClick={() => navigate('/')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 50 }}>
        HOME
      </text>

      <text onClick={() => navigate('/Web2')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        ABOUT US
      </text>

      <text onClick={() => scroll()} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        CONTACT US
      </text>


    </div>

    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img src={left} style={{ width: 0.40 * width, height: 130, alignSelf: 'center' }} />
      <div style={{ width: 164, height: 88, alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
        <text style={{ fontSize: 16, fontWeight: 700, alignSelf: 'center' }}>
          Privacy Policy
        </text>
      </div>
      <img src={right} style={{ width: 0.40 * width, height: 130, alignSelf: 'center' }} />
    </div>


    <div style={{ display: 'flex', width: 0.8 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -65 }}>
      <text style={{ fontSize: 13, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', fontFamily: 'Poppins' }}>
        Privacy Policy for "DaysWork Ltd" Mobile App:
      </text>


      <text style={{ fontSize: 13, fontWeight: '500', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        At DaysWork Ltd, we are committed to protecting your privacy and ensuring the security of your personal information.This Privacy Policy outlines how we collect, use, and safeguard your data when you use our mobile app.
      </text>



      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>1. **Information Collection**:</text>  We may collect personal information such as your name, email address, and payment details when you register or use our app.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>2. **Usage Data**:</text> We may collect information on how you interact with our app, including your device information, IP address, and usage patterns.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>3. **Data Usage**:</text> Your data is used to provide and improve our services, personalise your experience, and communicate with you about updates and promotions.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>4. **Data Sharing**:</text> We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except for trusted third parties who assist us in operating our app.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>5. **Data Security**:</text> We implement security measures to protect your data from unauthorised access, alteration, disclosure, or destruction.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>6. **Cookies**:</text> We may use cookies and similar tracking technologies to enhance your experience and analyse how our app is used.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>7. **Third-Party Links**:</text> Our app may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices of these third parties.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>8. **Updates to Privacy Policy**:</text> We reserve the right to update our Privacy Policy to reflect changes in our practices. We will notify you of any significant updates.

      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>9. **User Control**:</text> You can review and update your personal information or opt-out of certain data uses by contacting us directly.
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
        <text style={{ fontSize: 12, fontWeight: 'bold' }}>10. **Consent**:</text> By using our app, you consent to our Privacy Policy and agree to the collection and use of information as outlined herein.
      </text>






      <text onClick={() => sendMail()} style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>

        If you have any questions or concerns about our Privacy Policy, please contact us at <text style={{ fontSize: 12, color: 'green' }}>info@dayswork.com.</text>
      </text>



      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        This Privacy Policy is effective as of 22.02.2024 and is subject to periodic updates. Thank you for trusting DaysWork Ltd with your information.
      </text>


    </div>


    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.98 * width, height: 140, alignItems: 'center', backgroundColor: '#091E25', alignSelf: 'center', justifyContent: 'space-between' }}>

        <div style={{ display: 'flex', flexDirection: 'column', width: 110, height: 110, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start', marginLeft: 4 }} />
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, textAlign: 'left', marginLeft: 4 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            EN9 1EJ
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', width: 80, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 10 }}>

            <div onClick={() => openFace()} style={{ width: 22, height: 22 }}>
              {facebook}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 85, height: 150, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 105, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -16 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 95, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -62 }}>
          <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>   info@dayswork.com</a>


        </div>

      </div>

    </div>

    <div style={{ display: 'flex', width: width, height: 60, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
      <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
        DaysWork Today - A Career Forever
      </text>

    </div>

    <div ref={ref} />

  </div>


  return (ismobile() ? mobile_privacy :
    <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
      <div ref={ref1} />
      <div style={{ display: 'flex', backgroundColor: '#091E25', width: width, height: 157, justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: 0.80 * width, height: 156, alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#091E25', alignSelf: 'center' }}>

          <img src={one} style={{ width: 180, height: 125, alignSelf: 'center' }} />

          <div>
            <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', }}>
              HOME
            </text>

            <text onClick={() => navigate('/Web2')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              ABOUT US
            </text>

            <text onClick={() => { scroll() }} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              CONTACT US
            </text>
          </div>

        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={left} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />


        <div style={{ width: 0.10 * width, height: 188, alignItems: 'center', justifyContent: 'center', marginTop: 130 }}>
          <text style={{ fontSize: 25, fontWeight: 700, alignSelf: 'center', fontFamily: 'Poppins' }}>
            Privacy Policy
          </text>
        </div>

        <img src={right} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />
      </div>



      <div style={{ display: 'flex', width: 0.85 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -355 }}>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', fontFamily: 'Poppins' }}>
          Privacy Policy for "DaysWork Ltd" Mobile App:
        </text>


        <text style={{ fontSize: 23, fontWeight: '500', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
          At DaysWork Ltd, we are committed to protecting your privacy and ensuring the security of your personal information.This Privacy Policy outlines how we collect, use, and safeguard your data when you use our mobile app.
        </text>



        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>1. **Information Collection**:</text>  We may collect personal information such as your name, email address, and payment details when you register or use our app.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>2. **Usage Data**:</text> We may collect information on how you interact with our app, including your device information, IP address, and usage patterns.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>3. **Data Usage**:</text> Your data is used to provide and improve our services, personalise your experience, and communicate with you about updates and promotions.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>4. **Data Sharing**:</text> We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except for trusted third parties who assist us in operating our app.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>5. **Data Security**:</text> We implement security measures to protect your data from unauthorised access, alteration, disclosure, or destruction.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>6. **Cookies**:</text> We may use cookies and similar tracking technologies to enhance your experience and analyse how our app is used.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>7. **Third-Party Links**:</text> Our app may contain links to third-party websites or services that are not operated by us. We are not responsible for the privacy practices of these third parties.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>8. **Updates to Privacy Policy**:</text> We reserve the right to update our Privacy Policy to reflect changes in our practices. We will notify you of any significant updates.

        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>9. **User Control**:</text> You can review and update your personal information or opt-out of certain data uses by contacting us directly.
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          <text style={{ fontSize: 20, fontWeight: 'bold' }}>10. **Consent**:</text> By using our app, you consent to our Privacy Policy and agree to the collection and use of information as outlined herein.
        </text>






        <text onClick={() => sendMail()} style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>

          If you have any questions or concerns about our Privacy Policy, please contact us at <text style={{ fontSize: 20, color: 'green', cursor: 'pointer' }}>info@dayswork.com.</text>
        </text>



        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
          This Privacy Policy is effective as of 22.02.2024 and is subject to periodic updates. Thank you for trusting DaysWork Ltd with your information.
        </text>


      </div>



      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 397, alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#091E25', marginTop: 40 }}>


        <div style={{ display: 'flex', flexDirection: 'column', width: 305, height: 277, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 187, height: 127, alignSelf: 'flex-start' }} />

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, marginLeft: 6 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 1, marginLeft: 6 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            EN9 1EJ
          </text>
          <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 20 }}>

            <div onClick={() => openFace()} style={{ cursor: 'pointer', width: 40, height: 40 }}>
              {facebookb}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 140, height: 210, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 160, height: 172, alignItems: 'center', justifyContent: 'center', marginTop: -24 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 243, height: 160, alignItems: 'center', justifyContent: 'center', marginTop: -105 }}>
          <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: 233, alignItems: 'center', justifyContent: 'flex-start', marginTop: 5 }}>
            <img src={F4} style={{ width: 18, height: 18, alignSelf: 'flex-start' }} />
            <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 15, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 5 }}>
              info@dayswork.com
            </a>
          </div>

        </div>

      </div>

      <div style={{ display: 'flex', width: width, height: 93, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
        <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'center', color: '#091E25' }}>
          DaysWork Today - A Career Forever
        </text>

      </div>

      <div ref={ref} />

    </div>


  );
}

export default Privacy;