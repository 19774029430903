import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import A1 from './A1.png'
import A2 from './A3.png'
import A from './A.png'
import A3 from './A3.png'
import A4 from './A4.png'
import A5 from './A5.png'
import A6 from './A6.png'
import A7 from './A7.png'
import A8 from './A8.png'
import A9 from './A9.png'
import A10 from './A10.png'
import j from './j.png'
import B1 from './B1.png'
import B2 from './B2.png'
import B3 from './B3.png'
import B4 from './B4.png'
import B5 from './B5.png'
import B6 from './B6.png'
import cc from './cc.png'
import map from './map.png'
import map2 from './map2.png'
import F3 from './F3.png'
import gc from './gc.png'
import gd from './gd.png'
import one from './one.png'
import y from './y.png'
import google from './google.png'
import apple from './apple.png'
import n from './n.png'
import nn from './nn.png'
import B7 from './B7.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import C2 from './C2.png'
import C7 from './C7.png'
import C8 from './C8.png'
import C9 from './C9.png'
import D1 from './D1.png'
import D2 from './D2.png'
import D3 from './D3.png'
import D4 from './D4.png'
import D5 from './D5.png'
import D6 from './D6.png'
import D7 from './D7.png'
import D8 from './D8.png'
import D9 from './D9.png'
import E1 from './E1.png'
import E2 from './E2.png'
import E3 from './E3.png'
import E4 from './E4.png'
import E5 from './E5.png'
import E6 from './E6.png'
import E7 from './E7.png'
import E8 from './E8.png'
import E9 from './E9.png'
import F1 from './F1.png'
import F2 from './F2.png'
import jsonp from 'jsonp';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';


function Mobile1() {

     const navigate=useNavigate()


     const [width, setWidth] = useState(window.innerWidth)
     const [height, setHeight] = useState(window.innerHeight)

     function handleWindowSizeChange() {
          setWidth(window.innerWidth);
          setHeight(window.innerHeight);
     }

     const [mail, setMail] = useState('')

     useEffect(() => {
          window.addEventListener('resize', handleWindowSizeChange);
          return () => {
               window.removeEventListener('resize', handleWindowSizeChange);
          }
     }, []);

     const ref = useRef()

     function scroll(){
          ref.current.scrollIntoView()   
     }

     function submit() {
          // https://dayswork.us22.list-manage.com/subscribe/post-json?u=2cacf7115c5b4f691e362274a&amp;id=5524d3f8f4&amp;f_id=00cfc8e1f0
          const url = 'https://dayswork.us22.list-manage.com/subscribe/post-json?u=2cacf7115c5b4f691e362274a&amp;id=5524d3f8f4&amp;f_id=00cfc8e1f0';
          jsonp(`${url}&EMAIL=${mail}`, { param: 'c' }, (_, data) => {
               const { msg, result } = data
               console.log('Msg ', msg, result)
               if (result == 'success') {
                    toast('Thank you for subscribing!')
               }
               else toast(msg)
          });
     }


     return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
               <img src={j} style={{ width: width, height: 290, alignSelf: 'center' }} />

               <div style={{ display: 'flex', flexDirection: 'row', width: 384, height: 79, alignItems: 'center', justifyContent: 'center', marginTop: -248 }}>

                    <img src={one} style={{ width: 90, height: 61, alignSelf: 'center' }} />
                    <text style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 70, marginTop: -8 }}>
                         HOME
                    </text>

                    <text onClick={()=>navigate('/Mobile2')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13, marginTop: -8 }}>
                         ABOUT US
                    </text>

                    <text onClick={()=>{scroll()}} style={{cursor:'pointer', fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13, marginTop: -8 }}>
                         CONTACT US
                    </text>


               </div>


               <div style={{ width: 280, height: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: 0, marginTop: -7 }}>
                    <img src={y} style={{ width: 150, height: 50, alignSelf: 'center' }} />



                    <div style={{ width: 130, height: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignSelf: 'center', alignItems: 'center', marginLeft: 10, marginTop: 0 }}>
                         <img src={google} style={{ width: 65, height: 23, alignSelf: 'center', marginRight: 3 }} />
                         <img src={apple} style={{ width: 65, height: 23, alignSelf: 'center', marginLeft: 3 }} />
                    </div>

               </div>


               <div style={{ width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'flex-start',  marginTop: 12, }}>

                    <div style={{ width: 250, height: 5, display: 'flex', justifyContent: 'center', alignSelf: 'center',  marginTop: 0, }}>
                         <text style={{ fontSize: 11, fontWeight: 600, alignSelf: 'center', color: '#FFFFFF' }}>
                              Signup to our mailing list for when we go LIVE!
                         </text>
                    </div>

                    <div style={{ display: 'flex', width: 250, height: 32, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-around', marginTop: 12,marginLeft:14 }}>
                         <div style={{ display: 'flex', width: 180, height: 37, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                              <input placeholder='Email address' value={mail} onChange={(e) => setMail(e.currentTarget.value)} style={{borderRadius: 10,  color: '#B2BEB5',  outline: 'none', display: 'flex', width: 168, height: 35, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none', fontWeight: '600', fontSize: 10 }} />
                         </div>
                         <div onClick={()=>submit()} style={{ display: 'flex', width: 55, height: 34, borderRadius: 8, backgroundColor: '#8DC63F', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                              <text style={{ fontSize: 10, fontWeight: 400, alignSelf: 'center', color: 'white' }}>
                                   Sign Up
                              </text>
                         </div>
                    </div>
               </div>

               <wc-toast></wc-toast>


               <img src={gc} style={{ width: 295, height: 300, alignSelf: 'end', marginTop: 0 }} />

               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 25 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         About the App
                    </text>
                    <text style={{ fontSize: 28, fontWeight: 700, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         What is Dayswork App?
                    </text>
                    <img src={C8} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>



               <img src={n} style={{ width: 260, height: 260, alignSelf: 'center', marginTop: 10 }} />



               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 20 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Availability
                    </text>
                    <img src={C9} style={{ width: 250, height: 22, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={D1} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={A1} style={{ width: 260, height: 296, alignSelf: 'center' }} />



               <img src={map} style={{ width: width, height: 169, alignSelf: 'start' }} />
               <img src={map2} style={{ width: 0.90 * width, height: 169, alignSelf: 'center', marginTop: -169 }} />



               <div style={{ display: 'flex', flexDirection: 'column', width: 0.56 * width, height: 200, alignSelf: 'end', alignItems: 'center', justifyContent: 'right', marginTop: -153 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: 'black', marginRight: 0 }}>
                         Location
                    </text>
                    <text style={{ fontSize: 15, fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 5 }}>
                         Location feature?
                    </text>
                    <img src={D3} style={{ width: 190, height: 90, alignSelf: 'flex-start', marginTop: 5 }} />

                    <div style={{ width: 70, height: 10, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: 'black' }}>
                              Learn More
                         </text>
                         <img src={F3} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 0 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Review
                    </text>
                    <img src={D2} style={{ width: 250, height: 22, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={D3} style={{ width: 290, height: 84, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={A4} style={{ width: 260, height: 346, alignSelf: 'center' }} />




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 30 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Jobs
                    </text>
                    <img src={D6} style={{ width: 250, height: 36, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={D7} style={{ width: 290, height: 94, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn about Dayswork
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={A5} style={{ width: 260, height: 251, alignSelf: 'center', marginTop: 20 }} />





               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 40 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Target Market
                    </text>
                    <img src={D8} style={{ width: 250, height: 88, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={D9} style={{ width: 290, height: 80, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={A7} style={{ width: 260, height: 359, alignSelf: 'center', marginTop: 25 }} />




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 50 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Lorem text
                    </text>
                    <img src={F1} style={{ width: 250, height: 110, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={E1} style={{ width: 290, height: 90, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn more
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={A9} style={{ width: 260, height: 433, alignSelf: 'center', marginTop: 40 }} />




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 0 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         How we work
                    </text>
                    <img src={E2} style={{ width: 250, height: 22, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={E3} style={{ width: 290, height: 55, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={B2} style={{ width: 260, height: 174, alignSelf: 'center' }} />




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 20 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Our Mission
                    </text>
                    <img src={E4} style={{ width: 100, height: 17, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={E5} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 10 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={B3} style={{ width: 260, height: 174, alignSelf: 'center', marginTop: 10 }} />




               <div style={{ display: 'flex', flexDirection: 'column', width: 330, height: 150, alignSelf: 'center', alignItems: 'flex-start', justifyContent: 'center', marginTop: 40 }}>
                    <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0 }}>
                         Stats
                    </text>
                    <img src={E6} style={{ width: 120, height: 20, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={E7} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 10 }} />
                    <img src={E8} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 5 }} />
                    <img src={E9} style={{ width: 290, height: 75, alignSelf: 'flex-start', marginTop: 5 }} />

                    <div style={{ width: 130, height: 20, display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center', justifyContent: 'flex-start', marginTop: 10 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'center', color: '#8DC63F' }}>
                              Learn More
                         </text>
                         <img src={F2} style={{ width: 8, height: 6, alignSelf: 'center', marginLeft: 10 }} />
                    </div>
               </div>
               <img src={B6} style={{ width: 260, height: 260, alignSelf: 'center', marginTop: 10 }} />


               <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 120, height: 110, alignItems: 'center', justifyContent: 'center' }}>
                         <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start' }} />
                         <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginTop: 5 }}>
                              Lorem Ipsum is simply dummy text of
                         </text>
                         <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
                              the printing and typesetting industry.
                         </text>

                         <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
                              Lorem Ipsum has been the industry's
                         </text>
                         <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
                              standard dummy text ever
                         </text>

                         <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'flex-start', marginTop: 10 }}>
                              <img src={B8} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
                              <img src={B9} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
                              <img src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
                              <img src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
                         </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 77, height: 150, alignItems: 'center', justifyContent: 'center' }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Home
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              About the App
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Availability
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Location Feature
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Mission
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Posting a Job
                         </text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 80, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -5 }}>
                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Review Profiles
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Slavery Act
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Statistics
                         </text>

                         <text onClick={()=>navigate('/Privacy')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Privacy Policy
                         </text>

                         <text style={{ fontSize: 7, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              Terms & Conditions
                         </text>


                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 80, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -17 }}>
                         <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
                              Contact Us
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>
                              1 The High Street,
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              London, W1 1EL
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
                              +44 207 123 123
                         </text>

                         <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>
                              hello@DaysWork.com
                         </text>


                    </div>

               </div>

               <div style={{ display: 'flex', width: width, height: 45, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
                         DaysWork Today - A Career Forever
                    </text>

               </div>

               <div ref={ref}/>

          </div>


     );
}

export default Mobile1;
