import logo from '../logo.svg';
import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import home from './home.png'
import b from './b.png'
import left from './left.png'
import right from './right.png'
import c from './c.png'
import d from './d.png'
import e from './e.png'
import f from './f.png'
import g from './g.png'
import h from './h.png'
import i from './i.png'
import one from './one.png'
import day from './day.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import B7 from './B7.png'
import last from './last.png'
import C3 from './C3.png'
import C4 from './C4.png'
import C5 from './C5.png'
import C6 from './C6.png'
import { useNavigate } from 'react-router-dom';


function Mobile2() {

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  const navigate = useNavigate()

  const ref = useRef()

  function scroll() {
    ref.current.scrollIntoView()
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column',width:width }}>
      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25' }}>

        <img src={one} style={{ width: 130, height: 58, alignSelf: 'center' }} />
        <text onClick={() => navigate('/')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 50 }}>
          HOME
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 13 }}>
          ABOUT US
        </text>

        <text onClick={() => { scroll() }} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
          CONTACT US
        </text>


      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={left} style={{ width: 155, height: 145, alignSelf: 'center' }} />
        <div style={{ width: 80, height: 88, alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <text style={{ fontSize: 15, fontWeight: 700, alignSelf: 'center' }}>
            ABOUT US
          </text>
        </div>
        <img src={right} style={{ width: 155, height: 145, alignSelf: 'center' }} />
      </div>


      <img src={C4} style={{ width: 330, height: 259, alignSelf: 'center', marginTop: -30 }} />
      <img src={C3} style={{ width: 260, height: 216, alignSelf: 'center' }} />
      <img src={C5} style={{ width: 330, height: 259, alignSelf: 'center', marginTop: 10 }} />
      <img src={C6} style={{ width: 260, height: 216, alignSelf: 'center' }} />






      <div style={{ display: 'flex', width: width, height: 104, alignContent: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 25 }}>
        <img src={f} style={{ width: 350, height: 110, alignSelf: 'center' }} />
      </div>

      <div style={{ display: 'flex', width: width, height: 126, alignContent: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 20 }}>
        <img src={g} style={{ width: 350, height: 134, alignSelf: 'center' }} />
      </div>

      <div style={{ display: 'flex', width: width, height: 121, alignContent: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 20 }}>
        <img src={h} style={{ width: 350, height: 128, alignSelf: 'center' }} />
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25', marginTop: 35 }}>


        <div style={{ display: 'flex', flexDirection: 'column', width: 120, height: 110, alignItems: 'center', justifyContent: 'center', justifySelf: 'flex-start', marginLeft: -20 }}>
          <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start' }} />
          <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginTop: 5 }}>
            Lorem Ipsum is simply dummy text of
          </text>
          <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
            the printing and typesetting industry.
          </text>

          <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
            Lorem Ipsum has been the industry's
          </text>
          <text style={{ fontSize: 5, fontWeight: 200, alignSelf: 'flex-start', color: '#FFFFFF', marginRight: 0 }}>
            standard dummy text ever
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'flex-start', marginTop: 10 }}>


            <img src={B8} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
            <img src={B9} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
            <img src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
            <img src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start', marginLeft: 5 }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 77, height: 150, alignItems: 'center', justifyContent: 'center', marginLeft: -5 }}>
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 80, height: 150, alignItems: 'center', justifyContent: 'center', marginLeft: 5, marginTop: -5 }}>
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text style={{ fontSize: 8, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 80, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -17, marginRight: -5 }}>
          <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            1 The High Street,
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            London, W1 1EL
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            +44 207 123 123
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            hello@DaysWork.com
          </text>


        </div>

      </div>

      <div style={{ display: 'flex', width: width, height: 45, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
        <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
          DaysWork Today - A Career Forever
        </text>

      </div>
      <div ref={ref} />
    </div>
  );
}

export default Mobile2;
