import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import home from './home.png'
import b from './b.png'
import left from './left.png'
import right from './right.png'
import c from './c.png'
import d from './d.png'
import e from './e.png'
import f from './f.png'
import g from './g.png'
import h from './h.png'
import i from './i.png'
import one from './one.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import B7 from './B7.png'
import day from './day.png'
import F4 from './F4.png'
import C5 from './C5.png'
import C6 from './C6.png'
import F5 from './F5.png'
import C3 from './C3.png'
import C4 from './C4.png'
import { Link, useNavigate } from 'react-router-dom';
import { MdFacebook } from "react-icons/md";
import G1 from './G1.png'
import G2 from './G2.png'
import man from './man.png'
// #1d3238

const facebook = <div style={{ width: 22, height: 22, backgroundColor: '#1d3238', borderRadius: 11, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={12} /></div>
const facebookb = <div style={{ width: 40, height: 40, backgroundColor: '#1d3238', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={22} /></div>


function Web2() {

  function openLinked() {
    window.open('https://www.linkedin.com/company/dayswork/', "_blank", "noreferrer");
  }

  function openFace() {
    window.open('https://www.facebook.com/share/9PDgcw7aeC5g5jcp/?mibextid=LQQJ4d', "_blank", "noreferrer");
  }

  function openInstagram() {
    window.open('https://www.instagram.com/daysworkapp?igsh=MXUzMTZsN2EzdnNmMQ%3D%3D&utm_source=qr', "_blank", "noreferrer");
  }


  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const navigate = useNavigate()

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [mail, setMail] = useState('')
  const ref1 = useRef()
  const ref = useRef()

  function scroll() {
    ref.current.scrollIntoView()
  }

  useEffect(() => {
    ref1.current.scrollIntoView()
  }, [])

  function ismobile() {
    return width <= 700
  }

  const mobile2 = <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
    <div ref={ref1} />
    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25' }}>

      <img src={one} style={{ height: 58, alignSelf: 'center' }} />
      <text onClick={() => navigate('/')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 50 }}>
        HOME
      </text>

      <text style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 13 }}>
        ABOUT US
      </text>

      <text onClick={() => { scroll() }} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        CONTACT US
      </text>


    </div>



    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img src={left} style={{ width: 0.45 * width, height: 145, alignSelf: 'center' }} />
      <div style={{ width: 90, height: 88, alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
        <text style={{ fontSize: 15, fontWeight: 700, alignSelf: 'center' }}>
          ABOUT US
        </text>
      </div>
      <img src={right} style={{ width: 0.45 * width, height: 145, alignSelf: 'center' }} />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: -50 }}>
      <img src={c} style={{ width: 250, height: 205, alignSelf: 'center' }} />
      <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>

        <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F', marginTop: 10, textAlign: 'left' }}>
          About Us
        </text>
        <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'center', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          Who and What is ‘Days Work’?...
        </text>
        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.85 * width, fontFamily: 'Poppins', alignSelf: 'center', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          Jamie Davis, Callum Davis, and Harry Blake. Between the three of us we have produced and turned into reality an idea of an app/platform where we will be aiming for a target audience of those in need of that one opportunity that can enhance their chances of finding a career, as well as helping small business enhance their growth plan. Our target audience on the candidate side is generally anybody in need of that extra bit of money, or a push to have some direction and a career. On the client / hirer side, we are looking to provide determined and hungry candidates at a fantastic rateable value. We do not discriminate, and this would include youth, apprentices, college candidates and including those who are perhaps troubled in their personal lives, even having criminal records and maybe feeling lost and with no real guidance or support to make that change we all deserve in life. So many people out there aren’t given an opportunity because of “lack of experience” but everyone starts somewhere - Or who they may have been in the past! Days Work and our mission is to help as many people, which will be the candidates and hirers alike, to help boost the economy, give out chances and of course aid the shortness of labour in the UK
        </text>
      </div>
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
      <img src={G1} style={{ width: 250, height: 205, alignSelf: 'center' }} />
      <div style={{ display: 'flex', flexDirection: 'column', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
        <text style={{ fontSize: 16, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'center', color: '#8DC63F', marginTop: 10, textAlign: 'left' }}>
          About Us
        </text>
        <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'center', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          Who and What is ‘Days Work’?...
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.85 * width, fontFamily: 'Poppins', alignSelf: 'center', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          The luxury of days work is not only the price point being a lot more achievable for hirers not having to use job boards and recruitment agencies, this is going to be an instantaneous app… if you needed a ‘Days Work’ or someone who requires assistance tomorrow morning / last minute, there are no boundaries to what this platform can achieve. Not only short term and ad-hoc bookings though, we cater for all sectors, duration and timeframes on what we believe to be a user friendly, and unmatched platform.
        </text>

        <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
          <img src={G2} style={{ width: 14, height: 14, alignSelf: 'flex-start' }} />
          <text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.80 * width, alignSelf: 'center', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
            Very simple and easy to use. On your phone! A lot of people (ourselves included in the past) have
          </text>
        </div>

        <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <img src={G2} style={{ width: 14, height: 14, alignSelf: 'flex-start' }} />
          <text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.80 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
            Bypassing recruitment companies and other apps in a similar (but again, we are unmatched) space.
          </text>
        </div>

        <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <img src={G2} style={{ width: 14, height: 14, alignSelf: 'flex-start' }} />
          <text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.80 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
            No discrimination.
          </text>
        </div>

        <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
          <img src={G2} style={{ width: 14, height: 14, alignSelf: 'flex-start' }} />
          <text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.80 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
            Cheaper solution for both candidate and client user.
          </text>
        </div>
        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.85 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 30, textAlign: 'left' }}>
          In summary, our focus is to create a platform that will help reduce unemployment, give the construction industry scheme a massive boost, and help anybody who feels lost, or trapped in their current life cycle.
        </text>


      </div>

    </div>



    <div style={{ display: 'flex', width: 0.85 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'center', alignSelf: 'center', marginTop: 20 }}>

      <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
        <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 0, textAlign: 'left' }}>
          Harry
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
          Harry is a young entrepreneur who has always possessed innovative ideas and drive to succeed. Harry’s focus now is mainly in recruitment being a director of a small/mid-size firm specialising in 4 different sectors. Industrial and Logistics, Construction, Education and Medical… It could have a been very different career path however… upon leaving school Harry joined an accountancy practice on an apprenticeship scheme, achieving his level 2 and 3 AAT certificates before deciding that it wasn’t the career for him. Like with Days Work, we all need to start somewhere however!
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          This being said, Harry is extremely grateful for the knowledge and experience gained across his professional careers and employment which has enabled him to understand the fundamentals how business works in accountancy and then running a business with We Staff.  Harry has boundless plans for Days Work, and with his knowledge and the dedication from our team, we’re all very excited for the future!
        </text>

      </div>
    </div>




    <div style={{ display: 'flex', width: 0.85 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'center', alignSelf: 'center', marginTop: 20 }}>

      <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
        <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          Jamie
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
          Jamie is an individual who consistently strives to excel in every challenge he undertakes. Beginning his journey in college studying Plumbing and Heating he diligently acquired all the necessary qualifications for his business in Plumbing, Heating, and Gas services which he has successfully run for 4 years.
          Recognizing the limitations of classroom learning early on, Jamie proactively pursued an apprenticeship that spanned three years, culminating in the attainment of essential certifications.
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>

          Throughout his college days, Jamie supplemented his income by working in an al a carte restaurant, honing valuable communication skills along the way. Acknowledging the scarcity of apprenticeships and entry-level opportunities in various industries, he conceived the idea for an intuitive app, which he named "DaysWork." This app allows individuals to effortlessly discover short-term work in their preferred field, whether to enhance their expertise through hands-on experience or to secure additional income.
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          As a founder of this app, his passion for helping people stems from a deep-rooted belief in the power of meaningful work to transform lives. Dedicated to creating a platform that not only connects individuals with job opportunities but also empowers them to pursue fulfilling careers. Witnessing the positive impact of gainful employment on individuals and communities motivates us to innovate and provide a solution that truly makes a difference in people's lives

        </text>

      </div>
    </div>




    <div style={{ display: 'flex', width: 0.85 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'center', alignSelf: 'center', marginTop: 20 }}>

      <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
        <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>
          Callum
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>


          Growing up in Newham, East London, and being raised by a single mother, Callum experienced limited opportunities firsthand. However, through determination and perseverance, he commuted to Hertfordshire daily, distributing his CV and seizing any chance available. Now, Callum aims to pave the way for individuals facing similar challenges. Continuously brainstorming ways to better himself and those around him, he has gained invaluable insights through his gas and heating business, collaborating with successful clients. This journey has taught Callum that regardless of background, everyone has the potential for greatness, even if they start from a disadvantaged position.
          Certainly:
        </text>

        <text style={{ fontSize: 12, fontWeight: 400, display: 'flex', width: 0.8 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>

          The vision for DAYSWORK LTD is to provide a lifeline for individuals who feel trapped in their current circumstances, offering them a pathway to new opportunities. Additionally, the aim is to facilitate the creation of strong professional networks, empowering individuals to thrive in their chosen careers. Moreover, there is a vision to provide a streamlined solution for recruiters, eliminating the need for costly recruitment agencies and simplifying the process of finding the perfect match for their businesses. By addressing these needs, the goal is to solve problems that many may not have even realized they were facing, ultimately contributing to a more efficient and equitable job market.
        </text>

      </div>
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.98 * width, height: 140, alignItems: 'center', backgroundColor: '#091E25', alignSelf: 'center', justifyContent: 'space-between' }}>

        <div style={{ display: 'flex', flexDirection: 'column', width: 110, height: 110, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start', marginLeft: 4 }} />
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, textAlign: 'left', marginLeft: 4 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            EN9 1EJ
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', width: 80, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 10 }}>

            <div onClick={() => openFace()} style={{ width: 22, height: 22 }}>
              {facebook}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 85, height: 150, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ pointer:'cursor',fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 105, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -16 }}>
          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{pointer:'cursor', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 95, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -62 }}>
          <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>
            info@dayswork.com
          </a>
        </div>

      </div>

    </div>

    <div style={{ display: 'flex', width: width, height: 60, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
      <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
        DaysWork Today - A Career Forever
      </text>

    </div>
    <div ref={ref} />
  </div>


  return (ismobile() ? mobile2 :
    <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
      <div ref={ref1} />
      <div style={{ display: 'flex', backgroundColor: '#091E25', width: width, height: 157, justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: 0.80 * width, height: 156, alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#091E25', alignSelf: 'center' }}>

          <img src={one} style={{ width: 180, height: 125, alignSelf: 'center' }} />

          <div>
            <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', }}>
              HOME
            </text>

            <text style={{ fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#8DC63F', marginLeft: 40 }}>
              ABOUT US
            </text>

            <text onClick={() => { scroll() }} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              CONTACT US
            </text>
          </div>

        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={left} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />
        <div style={{ width: 0.09 * width, height: 188, alignItems: 'center', justifyContent: 'center', marginTop: 100 }}>
          <text style={{ fontSize: 25, fontWeight: 700, alignSelf: 'center' }}>
            ABOUT US
          </text>
        </div>

        <img src={right} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-around', marginTop: -290 }}>
        <img src={c} style={{ width: 450, height: 415, alignSelf: 'center' }} />
        <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>

          <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
            About Us
          </text>
          <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
            Who and What is ‘Days Work’?...
          </text>
          <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
            Jamie Davis, Callum Davis, and Harry Blake. Between the three of us we have produced and turned into reality an idea of an app/platform where we will be aiming for a target audience of those in need of that one opportunity that can enhance their chances of finding a career, as well as helping small business enhance their growth plan. Our target audience on the candidate side is generally anybody in need of that extra bit of money, or a push to have some direction and a career. On the client / hirer side, we are looking to provide determined and hungry candidates at a fantastic rateable value. We do not discriminate, and this would include youth, apprentices, college candidates and including those who are perhaps troubled in their personal lives, even having criminal records and maybe feeling lost and with no real guidance or support to make that change we all deserve in life. So many people out there aren’t given an opportunity because of “lack of experience” but everyone starts somewhere - Or who they may have been in the past! Days Work and our mission is to help as many people, which will be the candidates and hirers alike, to help boost the economy, give out chances and of course aid the shortness of labour in the UK
          </text>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.85 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-around', marginTop: 60 }}>

        <div style={{ display: 'flex', flexDirection: 'column', width: 0.50 * width, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
          <text style={{ fontSize: 18, fontFamily: 'Poppins', fontWeight: 600, alignSelf: 'flex-start', color: '#8DC63F', marginRight: 0, textAlign: 'left' }}>
            About Us
          </text>
          <text style={{ fontSize: 40, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginRight: 0, textAlign: 'left' }}>
            Who and What is ‘Days Work’?...
          </text>

          <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.447 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
            The luxury of days work is not only the price point being a lot more achievable for hirers not having to use job boards and recruitment agencies, this is going to be an instantaneous app… if you needed a ‘Days Work’ or someone who requires assistance tomorrow morning / last minute, there are no boundaries to what this platform can achieve. Not only short term and ad-hoc bookings though, we cater for all sectors, duration and timeframes on what we believe to be a user friendly, and unmatched platform.
          </text>

          <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 30 }}>
            <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
            <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.42 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
              Very simple and easy to use. On your phone! A lot of people (ourselves included in the past) have
            </text>
          </div>

          <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
            <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.41 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
              Bypassing recruitment companies and other apps in a similar (but again, we are unmatched) space.
            </text>
          </div>

          <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
            <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.42 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
              No discrimination.
            </text>
          </div>

          <div style={{ justifyContent: 'center', alignSelf: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <img src={G2} style={{ width: 24, height: 24, alignSelf: 'flex-start' }} />
            <text style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', display: 'flex', width: 0.42 * width, alignSelf: 'flex-start', color: '#000000', marginLeft: 10, textAlign: 'left' }}>
              Cheaper solution for both candidate and client user.
            </text>
          </div>
          <text style={{ fontSize: 18, fontWeight: 400, display: 'flex', width: 0.40 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 30, textAlign: 'left' }}>
            In summary, our focus is to create a platform that will help reduce unemployment, give the construction industry scheme a massive boost, and help anybody who feels lost, or trapped in their current life cycle.
          </text>


        </div>

        <img src={G1} style={{ width: 450, height: 415, alignSelf: 'center' }} />
      </div>

      <div style={{ display: 'flex', width: 0.80 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'space-around', alignSelf: 'center', marginTop: 90, flexDirection: 'row', alignItems: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
          <text style={{ fontSize: 35, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 0, textAlign: 'left' }}>
            Harry
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
            Harry is a young entrepreneur who has always possessed innovative ideas and drive to succeed. Harry’s focus now is mainly in recruitment being a director of a small/mid-size firm specialising in 4 different sectors. Industrial and Logistics, Construction, Education and Medical… It could have a been very different career path however… upon leaving school Harry joined an accountancy practice on an apprenticeship scheme, achieving his level 2 and 3 AAT certificates before deciding that it wasn’t the career for him. Like with Days Work, we all need to start somewhere however!
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 10, textAlign: 'left' }}>
            This being said, Harry is extremely grateful for the knowledge and experience gained across his professional careers and employment which has enabled him to understand the fundamentals how business works in accountancy and then running a business with We Staff.  Harry has boundless plans for Days Work, and with his knowledge and the dedication from our team, we’re all very excited for the future!
          </text>

        </div>
      </div>

      <div style={{ display: 'flex', width: 0.80 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'space-around', alignSelf: 'center', marginTop: 40, flexDirection: 'row', alignItems: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
          <text style={{ fontSize: 35, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 0, textAlign: 'left' }}>
            Jamie
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>
            Jamie is an individual who consistently strives to excel in every challenge he undertakes. Beginning his journey in college studying Plumbing and Heating he diligently acquired all the necessary qualifications for his business in Plumbing, Heating, and Gas services which he has successfully run for 4 years.
            Recognizing the limitations of classroom learning early on, Jamie proactively pursued an apprenticeship that spanned three years, culminating in the attainment of essential certifications.
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 20, textAlign: 'left' }}>

            Throughout his college days, Jamie supplemented his income by working in an al a carte restaurant, honing valuable communication skills along the way. Acknowledging the scarcity of apprenticeships and entry-level opportunities in various industries, he conceived the idea for an intuitive app, which he named "DaysWork." This app allows individuals to effortlessly discover short-term work in their preferred field, whether to enhance their expertise through hands-on experience or to secure additional income.
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 20, textAlign: 'left' }}>
            As a founder of this app, his passion for helping people stems from a deep-rooted belief in the power of meaningful work to transform lives. Dedicated to creating a platform that not only connects individuals with job opportunities but also empowers them to pursue fulfilling careers. Witnessing the positive impact of gainful employment on individuals and communities motivates us to innovate and provide a solution that truly makes a difference in people's lives

          </text>

        </div>
      </div>

      <div style={{ display: 'flex', width: 0.80 * width, height: 'auto', paddingTop: 20, paddingBottom: 20, borderRadius: 20, alignContent: 'center', backgroundColor: '#D6E7C0', justifyContent: 'space-around', alignSelf: 'center', marginTop: 90, flexDirection: 'row', alignItems: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', alignSelf: 'center', height: 'auto', flexDirection: 'column' }}>
          <text style={{ fontSize: 35, fontFamily: 'Poppins', fontWeight: 700, alignSelf: 'flex-start', color: '#000000', marginTop: 0, textAlign: 'left' }}>
            Callum
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', textAlign: 'left' }}>


            Growing up in Newham, East London, and being raised by a single mother, Callum experienced limited opportunities firsthand. However, through determination and perseverance, he commuted to Hertfordshire daily, distributing his CV and seizing any chance available. Now, Callum aims to pave the way for individuals facing similar challenges. Continuously brainstorming ways to better himself and those around him, he has gained invaluable insights through his gas and heating business, collaborating with successful clients. This journey has taught Callum that regardless of background, everyone has the potential for greatness, even if they start from a disadvantaged position.
            Certainly:
          </text>

          <text style={{ fontSize: 15, fontWeight: 400, display: 'flex', width: 0.7 * width, fontFamily: 'Poppins', alignSelf: 'flex-start', color: '#000000', marginTop: 20, textAlign: 'left' }}>

            The vision for DAYSWORK LTD is to provide a lifeline for individuals who feel trapped in their current circumstances, offering them a pathway to new opportunities. Additionally, the aim is to facilitate the creation of strong professional networks, empowering individuals to thrive in their chosen careers. Moreover, there is a vision to provide a streamlined solution for recruiters, eliminating the need for costly recruitment agencies and simplifying the process of finding the perfect match for their businesses. By addressing these needs, the goal is to solve problems that many may not have even realized they were facing, ultimately contributing to a more efficient and equitable job market.
          </text>

        </div>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 397, alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#091E25', marginTop: 40 }}>


        <div style={{ display: 'flex', flexDirection: 'column', width: 305, height: 277, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 187, height: 127, alignSelf: 'flex-start' }} />

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5,marginLeft:6 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0,marginLeft:6  }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 1,marginLeft:6  }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0,marginLeft:6  }}>
            EN9 1EJ
          </text>
          <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 20 }}>

            <div onClick={() => openFace()} style={{ cursor: 'pointer', width: 40, height: 40 }}>
              {facebookb}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 140, height: 210, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{cursor: 'pointer',  fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{cursor: 'pointer',  fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{cursor: 'pointer',  fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{cursor: 'pointer',  fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text  onClick={() => navigate('/')} style={{cursor: 'pointer',  fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 160, height: 172, alignItems: 'center', justifyContent: 'center', marginTop: -24 }}>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
          Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
          Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 243, height: 160, alignItems: 'center', justifyContent: 'center', marginTop: -105 }}>
          <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: 233, alignItems: 'center', justifyContent: 'flex-start', marginTop: 5 }}>
            <img src={F4} style={{ width: 18, height: 18, alignSelf: 'flex-start' }} />
            <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 15, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 5 }}>
                                   info@dayswork.com
                              </a>
          </div>

        </div>

      </div>

      <div style={{ display: 'flex', width: width, height: 93, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
        <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'center', color: '#091E25' }}>
          DaysWork Today - A Career Forever
        </text>

      </div>

      <div ref={ref} />

    </div>
  );
}

export default Web2;
