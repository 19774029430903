import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  HashRouter
} from "react-router-dom";

import Web2 from './screens/Web2';
import Web1 from './screens/Web1';
import Mobile1 from './screens/Mobile1';
import Mobile2 from './screens/Mobile2';
import Abstract from './screens/Abstract';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';


function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>

          <Route path="/" element={<Web1 />} />
          <Route path="/Web2" element={<Web2 />} />
          <Route path="/Mobile1" element={<Mobile1 />} />
          <Route path="/Mobile2" element={<Mobile2 />} />

          <Route path="/Abstract" element={<Abstract />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Privacy" element={<Privacy />} />
          
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
