import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useRef, useState } from 'react';
import home from './home.png'
import b from './b.png'
import left from './left.png'
import right from './right.png'
import c from './c.png'
import d from './d.png'
import e from './e.png'
import f from './f.png'
import g from './g.png'
import h from './h.png'
import i from './i.png'
import one from './one.png'
import B8 from './B8.png'
import B9 from './B9.png'
import B10 from './B10.png'
import C1 from './C1.png'
import B7 from './B7.png'
import day from './day.png'
import F4 from './F4.png'
import F5 from './F5.png'
import { useNavigate, Link } from 'react-router-dom';
import { MdFacebook } from "react-icons/md";
// #1d3238

const facebook = <div style={{ width: 22, height: 22, backgroundColor: '#1d3238', borderRadius: 11, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={12} /></div>
const facebookb = <div style={{ width: 40, height: 40, backgroundColor: '#1d3238', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdFacebook color='white' size={22} /></div>


function Abstract() {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)



  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [mail, setMail] = useState('')
  const ref1 = useRef()
  const ref = useRef()

  function scroll() {
    ref.current.scrollIntoView()
  }

  useEffect(() => {
    ref1.current.scrollIntoView()
  }, [])


  function ismobile() {
    return width <= 700
  }

  function openLinked() {
    window.open('https://www.linkedin.com/company/dayswork/', "_blank", "noreferrer");
  }

  function openFace() {
    window.open('https://www.facebook.com/share/9PDgcw7aeC5g5jcp/?mibextid=LQQJ4d', "_blank", "noreferrer");
  }

  function openInstagram() {
    window.open('https://www.instagram.com/daysworkapp?igsh=MXUzMTZsN2EzdnNmMQ%3D%3D&utm_source=qr', "_blank", "noreferrer");
  }


  const navigate = useNavigate()

  const mobile_abstract = <div style={{ display: 'flex', flexDirection: 'column', width: width }}>
    <div ref={ref1} />
    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 79, alignItems: 'center', justifyContent: 'center', backgroundColor: '#091E25' }}>

      <img src={one} style={{ height: 58, alignSelf: 'center' }} />
      <text onClick={() => navigate('/')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 50 }}>
        HOME
      </text>

      <text onClick={() => navigate('/Web2')} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        ABOUT US
      </text>

      <text onClick={() => scroll()} style={{ fontSize: 12, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 13 }}>
        CONTACT US
      </text>


    </div>

    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <img src={left} style={{ width: 0.40 * width, height: 145, alignSelf: 'center' }} />
      <div style={{ width: 80, height: 88, alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
      
      </div>
      <img src={right} style={{ width: 0.40 * width, height: 145, alignSelf: 'center' }} />
    </div>




    <div style={{ display: 'flex', width: 0.8 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -80 }}>
      <text style={{ fontSize: 14, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', fontFamily: 'Poppins', textAlign: 'left' }}>
        Combating Modern Day Slavery:
      </text>
      <text style={{ fontSize: 14, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', fontFamily: 'Poppins', textAlign: 'left' }}>
        A Collective Effort by DAYSWORK LTD
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 15, fontFamily: 'Poppins', textAlign: 'left' }}>

        Modern day slavery remains a persistent global issue, infiltrating various industries and exploiting vulnerable individuals. As a company dedicated to connecting job seekers with employment opportunities through our innovative app, DAYSWORK LTD recognises the urgent need to address and prevent modern slavery within our operations and across our network of partners. This paper outlines our commitment to combating modern slavery, leveraging our platform to promote ethical labor practices, and fostering collaboration among companies, workers, and stakeholders to eradicate this grave violation of human rights.
      </text>



      <text style={{ fontSize: 15, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins' }}>
        1. Introduction:
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        Modern day slavery, encompassing practices such as forced labor, human trafficking, and exploitation, persists in the shadows of legitimate industries worldwide. As a platform that brings together companies and workers seamlessly, DAYSWORK LTD acknowledges our responsibility to safeguard against modern slavery and uphold the dignity and rights of all individuals within our ecosystem.
      </text>
      <text style={{ fontSize: 15, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        2. Platform Policies and Practices:
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        DAYSWORK LTD is committed to maintaining strict policies and practices to prevent and address modern slavery within our app-based platform. Key components include:
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Zero-tolerance policy towards modern slavery in any form.

      </text>


      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 5, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Thorough screening and verification processes for both companies and workers.
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 5, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Regular monitoring and reporting mechanisms to identify and address suspicious activities or concerns.
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 5, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Collaboration with law enforcement agencies and relevant authorities to respond to cases of suspected modern slavery.
      </text>


      <text style={{ fontSize: 15, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        3. Empowering Workers:
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        Our platform serves as a beacon of hope for individuals seeking employment opportunities, including those vulnerable to exploitation. DAYSWORK LTD is dedicated to empowering workers by:
      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Providing access to transparent job listings from reputable companies committed to ethical labor practices.

      </text>

      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 5, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Offering resources and support to educate workers about their rights and how to recognise signs of exploitation. - Establishing channels for workers to report concerns or seek assistance in cases of potential modern slavery.
      </text>

      <text style={{ fontSize: 15, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        4. Partnering for Impact:
      </text>

      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        DAYSWORK LTD recognises the power of collaboration in combatting modern slavery. We actively seek partnerships with companies, NGOs, government agencies, and other stakeholders to:
      </text>
      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Share best practices and resources for preventing and addressing modern slavery.
      </text>
      <text style={{ fontSize: 12, fontWeight: '500', alignSelf: 'flex-start', marginTop: 5, fontFamily: 'Poppins', textAlign: 'left' }}>
        - Advocate for stronger regulations and enforcement mechanisms to hold perpetrators accountable. - Support initiatives that promote ethical recruitment, fair wages, and safe working conditions.
      </text>
      <text style={{ fontSize: 15, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 30, fontFamily: 'Poppins', textAlign: 'left' }}>
        5. Conclusion:
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
        As a company committed to facilitating fair and ethical employment opportunities through our app, DAYSWORK LTD stands united in the fight against modern slavery. We recognise the importance of collective action and are dedicated to leveraging our platform to promote dignity, equality, and justice for all workers. Together, we can create a future where exploitation and abuse have no place in the workforce.
      </text>
      <text style={{ fontSize: 12, fontWeight: 500, alignSelf: 'flex-start', marginTop: 15, fontFamily: 'Poppins', textAlign: 'left' }}>
        This paper highlights DAYSWORK LTD's commitment to combating modern slavery by leveraging its platform to promote ethical labor practices and foster collaboration among companies, workers, and stakeholders. Feel free to customise it further to reflect your company's specific initiatives and objectives.
      </text>


    </div>

    <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 150, alignItems: 'center', backgroundColor: '#091E25', marginTop: 35, alignSelf: 'center', justifyContent: 'center' }}>

      <div style={{ display: 'flex', flexDirection: 'row', width: 0.98 * width, height: 140, alignItems: 'center', backgroundColor: '#091E25', alignSelf: 'center', justifyContent: 'space-between' }}>

        <div style={{ display: 'flex', flexDirection: 'column', width: 110, height: 110, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 80, height: 55, alignSelf: 'flex-start', marginLeft: 4 }} />
          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, textAlign: 'left', marginLeft: 4 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, textAlign: 'left', marginLeft: 4 }}>
            EN9 1EJ
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', width: 80, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 10 }}>

            <div onClick={() => openFace()} style={{ width: 22, height: 22 }}>
              {facebook}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ width: 22, height: 22, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 85, height: 150, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 105, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -16 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 95, height: 150, alignItems: 'center', justifyContent: 'center', marginTop: -62 }}>
          <text style={{ fontSize: 10, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 9, fontWeight: 500, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, wordBreak: 'break-all' }}>   info@dayswork.com</a>


        </div>

      </div>

    </div>

    <div style={{ display: 'flex', width: width, height: 60, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
      <text style={{ fontSize: 12, fontWeight: 600, alignSelf: 'center', color: '#091E25' }}>
        DaysWork Today - A Career Forever
      </text>

    </div>

    <div ref={ref} />

  </div>

  return (ismobile() ? mobile_abstract :
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div ref={ref1} />
      <div style={{ display: 'flex', backgroundColor: '#091E25', width: width, height: 157, justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', width: 0.80 * width, height: 156, alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#091E25', alignSelf: 'center' }}>

          <img src={one} style={{ width: 180, height: 125, alignSelf: 'center' }} />

          <div>
            <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', }}>
              HOME
            </text>

            <text onClick={() => navigate('/Web2')} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              ABOUT US
            </text>

            <text onClick={() => { scroll() }} style={{ cursor: 'pointer', fontSize: 18, fontWeight: 400, alignSelf: 'center', color: '#FFFFFF', marginLeft: 40 }}>
              CONTACT US
            </text>
          </div>

        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={left} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />

        {/* <div style={{ width: 0.10 * width, height: 188, alignItems: 'center', justifyContent: 'center', marginTop: 130, fontFamily: 'Poppins' }}>
          <text style={{ fontSize: 25, fontWeight: 700, alignSelf: 'center' }}>
            Abstract
          </text>
        </div> */}

        <img src={right} style={{ width: 0.45 * width, height: 700, alignSelf: 'center' }} />
      </div>

      <div style={{ display: 'flex', width: 0.8 * width, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: -480 }}>
        <text style={{ fontSize: 32, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', fontFamily: 'Poppins', textAlign: 'left' }}>
          Combating Modern Day Slavery: A Collective Effort by DAYSWORK LTD
        </text>


        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 75, fontFamily: 'Poppins', textAlign: 'left' }}>

          Modern day slavery remains a persistent global issue, infiltrating various industries and exploiting vulnerable individuals. As a company dedicated to connecting job seekers with employment opportunities through our innovative app, DAYSWORK LTD recognises the urgent need to address and prevent modern slavery within our operations and across our network of partners. This paper outlines our commitment to combating modern slavery, leveraging our platform to promote ethical labor practices, and fostering collaboration among companies, workers, and stakeholders to eradicate this grave violation of human rights.
        </text>



        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 50, fontFamily: 'Poppins' }}>
          1. Introduction:
        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          Modern day slavery, encompassing practices such as forced labor, human trafficking, and exploitation, persists in the shadows of legitimate industries worldwide. As a platform that brings together companies and workers seamlessly, DAYSWORK LTD acknowledges our responsibility to safeguard against modern slavery and uphold the dignity and rights of all individuals within our ecosystem.
        </text>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins', textAlign: 'left' }}>
          2. Platform Policies and Practices:
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          DAYSWORK LTD is committed to maintaining strict policies and practices to prevent and address modern slavery within our app-based platform. Key components include:
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Zero-tolerance policy towards modern slavery in any form.

        </text>


        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 0, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Thorough screening and verification processes for both companies and workers.
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 0, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Regular monitoring and reporting mechanisms to identify and address suspicious activities or concerns.
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 0, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Collaboration with law enforcement agencies and relevant authorities to respond to cases of suspected modern slavery.
        </text>


        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins', textAlign: 'left' }}>
          3. Empowering Workers:
        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          Our platform serves as a beacon of hope for individuals seeking employment opportunities, including those vulnerable to exploitation. DAYSWORK LTD is dedicated to empowering workers by:
        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Providing access to transparent job listings from reputable companies committed to ethical labor practices.

        </text>

        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 0, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Offering resources and support to educate workers about their rights and how to recognise signs of exploitation. - Establishing channels for workers to report concerns or seek assistance in cases of potential modern slavery.
        </text>

        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins', textAlign: 'left' }}>
          4. Partnering for Impact:
        </text>

        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          DAYSWORK LTD recognises the power of collaboration in combatting modern slavery. We actively seek partnerships with companies, NGOs, government agencies, and other stakeholders to:
        </text>
        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 10, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Share best practices and resources for preventing and addressing modern slavery.
        </text>
        <text style={{ fontSize: 20, fontWeight: '500', alignSelf: 'flex-start', marginTop: 0, fontFamily: 'Poppins', textAlign: 'left' }}>
          - Advocate for stronger regulations and enforcement mechanisms to hold perpetrators accountable. - Support initiatives that promote ethical recruitment, fair wages, and safe working conditions.
        </text>
        <text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: 'flex-start', marginTop: 40, fontFamily: 'Poppins', textAlign: 'left' }}>
          5. Conclusion:
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          As a company committed to facilitating fair and ethical employment opportunities through our app, DAYSWORK LTD stands united in the fight against modern slavery. We recognise the importance of collective action and are dedicated to leveraging our platform to promote dignity, equality, and justice for all workers. Together, we can create a future where exploitation and abuse have no place in the workforce.
        </text>
        <text style={{ fontSize: 20, fontWeight: 500, alignSelf: 'flex-start', marginTop: 20, fontFamily: 'Poppins', textAlign: 'left' }}>
          This paper highlights DAYSWORK LTD's commitment to combating modern slavery by leveraging its platform to promote ethical labor practices and foster collaboration among companies, workers, and stakeholders. Feel free to customise it further to reflect your company's specific initiatives and objectives.
        </text>


      </div>

      <div style={{ display: 'flex', flexDirection: 'row', width: width, height: 397, alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#091E25', marginTop: 40 }}>


        <div style={{ display: 'flex', flexDirection: 'column', width: 305, height: 277, alignItems: 'center', justifyContent: 'center' }}>
          <img src={B7} style={{ width: 187, height: 127, alignSelf: 'flex-start' }} />

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5, marginLeft: 6 }}>
            Knight Wheeler & Co
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            54 Sun Street
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 1, marginLeft: 6 }}>
            Waltham Abbey
          </text>

          <text style={{ fontSize: 14, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 6 }}>
            EN9 1EJ
          </text>
          <div style={{ display: 'flex', flexDirection: 'row', width: 150, height: 40, alignItems: 'center', alignSelf: 'flex-start', justifyContent: 'space-around', marginTop: 20 }}>

            <div onClick={() => openFace()} style={{ cursor: 'pointer', width: 40, height: 40 }}>
              {facebookb}
            </div>
            <img onClick={() => openInstagram()} src={B10} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
            <img onClick={() => openLinked()} src={C1} style={{ cursor: 'pointer', width: 40, height: 40, alignSelf: 'flex-start' }} />
          </div>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 140, height: 210, alignItems: 'center', justifyContent: 'center' }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Home
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            About the App
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Availability
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Location Feature
          </text>

          <text sonClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Mission
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Posting a Job
          </text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 160, height: 172, alignItems: 'center', justifyContent: 'center', marginTop: -24 }}>
          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Review Profiles
          </text>

          <text onClick={() => navigate('/Abstract')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Slavery Act
          </text>

          <text onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Statistics
          </text>

          <text onClick={() => navigate('/Privacy')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Privacy Policy
          </text>

          <text onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', fontSize: 16, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 5 }}>
            Terms & Conditions
          </text>


        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: 243, height: 160, alignItems: 'center', justifyContent: 'center', marginTop: -105 }}>
          <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'flex-start', color: '#D3D6D8' }}>
            Contact Us
          </text>

          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: 233, alignItems: 'center', justifyContent: 'flex-start', marginTop: 5 }}>
            <img src={F4} style={{ width: 18, height: 18, alignSelf: 'flex-start' }} />
            <a href='mailto:info@dayswork.com' style={{ textDecoration: 'none', cursor: 'pointer', fontSize: 15, fontWeight: 600, alignSelf: 'flex-start', color: '#D3D6D8', marginTop: 0, marginLeft: 5 }}>
              info@dayswork.com
            </a>
          </div>

        </div>

      </div>

      <div style={{ display: 'flex', width: width, height: 93, alignSelf: 'center', backgroundColor: '#8DC63F', justifyContent: 'center', alignItems: 'center' }}>
        <text style={{ fontSize: 18, fontWeight: 700, alignSelf: 'center', color: '#091E25' }}>
          DaysWork Today - A Career Forever
        </text>

      </div>

      <div ref={ref} />

    </div>


  );
}

export default Abstract;